import * as CryptoJS from 'crypto-js';
import { SECRET_KEY } from '../constant/commonConst';

export const getValueByKey = (key: string): string | null => {
  return localStorage.getItem(key);
};
export const setValueByKey = (key: string, value: string): void => {
  return localStorage.setItem(key, value);
};
export const uuid = () => {
  const uniqueId = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  return uniqueId;
};
export const getParshData = (key: string) => {
  const parshData = JSON.parse(key);
  return parshData;
};
export const getShortUserName = (userName: String) => {
  const data = userName;
  const firstChar = data.charAt(0); // Get the first character 'H'
  const words = data.split(" "); // Split the string by space
  const firstCharAfterSpace = words.length > 1 ? words[1].charAt(0) : ""; // Get the first character after space 'W'
  return firstChar + firstCharAfterSpace;
};

export const getModuleName = (
  path: string,
  viewAddUpdate: boolean,
  currentValue: any
) => {
  switch (path) {
    case "/home":
      return "Dashboard";
    case "/user":
      return "User Management";
    case "/role":
      return "Role Management";
    case "/city":
      return "City Management";
    case "/pptSettings":
      return "PPT Settings";
    case "/state":
      return "State Management";
    case "/businessquotation":
      return "Business Proposal Report";
    case "/agency":
      return "Agency Management";
    case "/agency":
      return viewAddUpdate
        ? currentValue?.id
          ? "Edit Agency "
          : "Add Agency "
        : "Landlord Management";
    case "/location":
      return "Location Management";
    case "/landlord":
      return viewAddUpdate
        ? currentValue?.id
          ? "Edit Landlord "
          : "Add Landlord "
        : "Landlord Management";
    case "/zone":
      return "Zone Management";
    case "/mediaType":
      return "Media Type Management";
    case "/mediaSeries":
      return "Media Series Management";
    case "/mediaLit":
      return "Media Lit Type Management";
    case "/route":
      return "Route";
    case "/mediaItem":
    case "/mediaItem/:id":
      return viewAddUpdate
        ? currentValue?.id
          ? "Edit Media Item"
          : "Add Media Item"
        : "Media Items";
    case "/industry":
      return "Industry Management";
    case "/client":
      return viewAddUpdate
        ? currentValue?.id
          ? "Edit Client"
          : "Add Client"
        : "Client Management";
    case "/leadGeneration":
      return viewAddUpdate
        ? currentValue?.id
          ? "Edit Lead"
          : "Add Lead"
        : "Lead Generation";
    case "/booking":
      return "Booking Management";
    case "/booking/view":
      return "Booking Detail";
    case "/printing":
      return "Printing Management";
    case "/mounting":
      return "Mounting Management";
    case "/mountingReport":
      return "Mounting Report";
    case "/unmountingReport":
      return "UnMounting Report";
    case "/printingreport":
      return "Printing Report";
    case "/unMounting":
      return "Unmounting Management";
    case "/receipt":
      return "Receipt Management";
    case "/payment":
      return "Payment Management";
    case "/receiptreport":
      return "Receipt Payment Report";
    case "/businessProposal":
      return viewAddUpdate
        ? currentValue?.id
          ? "Edit Business Proposal"
          : "Add Business Proposal"
        : "Business Proposal Management";
    case "/replead":
      return "Lead Report";
    case "/mediaReport":
      return "Media Inventory ";
    case "/ledgerReport":
      return "Ledger Report";
    case "/bookingReport":
      return "Booking Report";
    default:
      return "Dashboard";
  }
};
export const capitalizeFirstLetter = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const encryptText = (value: any) => {
  const ciphertext = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
  return ciphertext;
};
export const decryptText = (decryptData: any) => {
  try {
    const bytes = CryptoJS.AES.decrypt(decryptData, SECRET_KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  } catch (error) {
    return null;
  }
};
export const setRole = (value: any) => {
  const encryptStorage = encryptText(value ? JSON.stringify(value) : "");
  setValueByKey("__access", encryptStorage)
}

export const getRole = () => {
  let encyptrole = getValueByKey("__access") ?? "";
  const decryptData: any = decryptText(encyptrole);
  const localStorageToken = getValueByKey("token");
  const JSONparse = decryptData ? JSON.parse(decryptData) : null;
  const JsonparseToken = localStorageToken ? JSON.parse(localStorageToken) : null;
  if (JSONparse?.token == JsonparseToken) {
    return JSONparse?.role
  } else {
    return null
  }
}


export const getStatusActiveTab = (status: string) => {
  switch (status) {
    case "waiting-for-creative":
      return "1"
    case "mounting-pending":
      return "2"
    case "un-mounting-pending":
      return "2"
    case "mounted":
      return "3"
    case "unmounted":
      return "3"
    default:
      return "1"
  }
}