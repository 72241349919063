import React, { useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import AddCellTable from "../../../components/table/addCellTable";
import { DataType } from "ka-table";
import { ActionButtons, DateView, InputCell, MediaItem, RouteID } from "../actions";
import TableExtent from "./tableextent";

export default function SoapkTable({ mediaInventory, bookingStartDate, setFieldValue }: any) {

  const [isModalOpen,setIsModal] =useState({open:false,data:null});

  const columns = [
    {
      key: "routeId",
      title: "Media Route ID",
      style: { width:"13vw" },
      dataType: DataType.String,
      component: RouteID,
      isDisabled:false,
    },
    { key: "zonedesc", title: "Zone Id", dataType: DataType.String,style: { width:"9vw" } },
    { key: "mediaType", title: "Media Type", dataType: DataType.String,style: { width:"13vw" } },
    { key: "mediaSeries", title: "Media Series", dataType: DataType.String,style: { width:"13vw" } },
    { key: "size", title: "size", dataType: DataType.String,style: { width:"8vw" } },
    { key: "sqft", title: "SQ.FT", dataType: DataType.String,style: { width:"6vw" } },
    {
      key: "qty", title: "Qty", dataType: DataType.Number,
      component: InputCell,
      style: { width: "7vw" },
      isEditable:true
    },
    {
      key: "day", title: "Day", dataType: DataType.Number,
      component: InputCell,
      style: { width: "7vw" },
      isEditable:true
    },
    {
      key: "rate", title: "Booking rate per month", dataType: DataType.Number,
      component: InputCell,
      style: { width: "9vw" },
      isEditable:true
    },
    { key: "startDate", title: "Start Date", 
      dataType: DataType.Date,
      valueConvert:DateView,
      style: { width: "6vw" } ,showDatepicker:true },
      
    { key: "endDate", title: "End Date", 
      dataType: DataType.Date,
      valueConvert:DateView,
      showDatepicker:true,
      style: { width: "6vw" } },
    { key: "amount", title: "Amount", dataType: DataType.String,style: { width: "7vw" } },
    {
      key: "addColumn",
      title: "",
      component: ActionButtons,
      style: { width: "7vw",textAlign: 'center' }, 
      isEditable:false,
      callBack:(value:any)=>{
        setIsModal({
          open:true,
          data:value
        })
      },
    },
  ];
  const dbId = "SOAPK"
  return (
    <>
      <AccordionItem>
        <AccordionHeader targetId={dbId}>
        SALE OF SPACE FOR OUTDOOR ADVERTISEMENT POLE KIOSK
        </AccordionHeader>
        <AccordionBody accordionId={dbId}>
          <AddCellTable
            bookingStartDate={bookingStartDate}
            groupCode={dbId}
            updateFieldValue={setFieldValue}
            columns={columns}
            rows={mediaInventory}
            setIsModal={setIsModal}
          />
        </AccordionBody>
      </AccordionItem>
      <TableExtent isEditable columns={columns} dbId={dbId} setIsModal={setIsModal} isModal={isModalOpen} mediaInventory={mediaInventory} bookingStartDate={bookingStartDate} setFieldValue={setFieldValue}/>
    </>
  );
}
