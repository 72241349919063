import { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
} from "reactstrap";
import {
  hamburgerMenu,
  leftSquareArrow,
  logoutDuotone,
} from "../../constant/icons";
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useAuth } from "../auth";
import {
  getModuleName,
  getRole,
  getShortUserName,
  getValueByKey,
} from "../../utils/generalUtils";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { ROLE } from "../../constant/commonConst";

export const Header = ({back}: any) => {

  const location = useLocation();
  const navigate = useNavigate();
  const { viewAddUpdate, currentValue, closeAddEditFrom } = useAddUpdate();

  const moduleName = getModuleName(
    location.pathname,
    viewAddUpdate,
    currentValue
  );
  const auth = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const username = getValueByKey("username");
  const role=getRole();
  const [backButton, setBackButton] = useState(null)
  return (
    <header className="header">
      <div className="left-panel">
        {viewAddUpdate ? (
          back?.backButton || role !== ROLE.PRINTINGUSER ? <Button
            onClick={() => {
              if (back.backButton) {
                back.setBackButton(null)
                return
              }
              viewAddUpdate ? closeAddEditFrom() : navigate(-1);
            }}
            className="hamburger-btn p-0"
          >
            {leftSquareArrow.icon}
          </Button> : null
        ) : ""}
        <h4>{moduleName}</h4>
      </div>
      <div className="right-panel">
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="profile-main"
        >
          <DropdownToggle color="link">
            <span>
              {username ? getShortUserName(username).toUpperCase() : ""}
            </span>
            <h6>
              {username}
              {/*        <small>{auth.user?.role}</small> */}
            </h6>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => auth.logOut()}>
              <span>{logoutDuotone.icon}</span>
              <p>
                Log Out
                <small></small>
              </p>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </header>
  );
};
