import PageLayout from "../../components/layout/page";
import Booking from "../booking";

export default function BookingReport() {
    return (
        <PageLayout className="dashboard-inner-page"
            View={
                <>
                    <Booking isBookingReport={true} />
                </>
            }
        />
    );
}