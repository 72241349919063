import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { useEffect, useState } from "react";
import PageLayout from "../../components/layout/page";
import { columns } from "./columns";
// import { AddUpdatePrinting } from "./addUpdate";
import { DetailsRow } from "./details";
import { DataView } from "../../components/table/table";
import { clickIcon } from "../../constant/icons";
import { useOutletContext } from "react-router-dom";

export const PrintingApi = (paging?: boolean, dataLoad: any = {},) => {
  return { ...useApi("/unmountings", paging, undefined, dataLoad) };
};
export default function UnMounting() {
  const api = PrintingApi(true);
  const AddUpdate = useAddUpdate();
  const [openDetailPrinting, setOpenDetailPrinting] = useState<any>(null);
  useEffect(() => {
    api.mutate();
  }, []);
  const back: any = useOutletContext();
  useEffect(() => {
    if (back.backButton === null) {
      setOpenDetailPrinting(null);
    }
  }, [back.backButton])
  const handelShowDetails = (rowData: any) => {
    back.setBackButton(true)
    setOpenDetailPrinting(rowData)
  }
  return (
    <>
      <PageLayout
          item={
            openDetailPrinting?.bookingNo && (
              <span className="booking-id">
                {openDetailPrinting?.bookingNo
                  ? openDetailPrinting?.bookingNo
                  : ""}
              </span>
            )
          }
        // item={
        //   <Button
        //     color="secondary"
        //     onClick={() =>
        //       AddUpdate.openAddEditFrom({
        //         posterQty: null,
        //         creativeUrl: null,
        //         posterWidth: null,
        //         posterHeight: null,
        //         assignTo: null,
        //         status: "pending",
        //       })
        //     }
        //   >
        //     {plus.icon}Add New
        //   </Button>
        // }
        className={"booking-master-page"}
        View={openDetailPrinting?.bookingId ? <DetailsRow rowData={openDetailPrinting} apiPrinting={api} setOpenDetailPrinting={setOpenDetailPrinting} openDetailPrinting={openDetailPrinting} /> :
          <>
            <DataView
              columns={columns({ api: api.data, openAddEditFrom: AddUpdate.openAddEditFrom, handelShowDetails })}
              onRowDoubleClick={(event: any, extendedEvent: any) => {
                back.setBackButton(true)
                setOpenDetailPrinting(extendedEvent.childProps.rowData)
              }}
              rows={api.data}
              currentPage={api.currentPage}
              rowCount={api.rowCount}
              limit={api.limit}
              updatePagination={api.updatePagination}
            />
            <div className="doubleclick_note">
              <p>
                <span>{clickIcon.icon}</span> Double click to view Data
              </p>
            </div>
          </>
        }
      />

    </>
  );
}

