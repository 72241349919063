import React, { useEffect, useState } from "react";
import {
  Container,
  TabContent,
  Col,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import { Formik } from "formik";
import { StateApi } from "../masters/state";
import Dropdown from "../../components/common/dropdown";
import TextBox from "../../components/common/textBox";
import ImageUpload from "../masters/items/imageInput";
import { useUpload } from "../masters/items/helper";
import { crossIcon } from "../../constant/icons";
import ProtectedImage from "../../components/common/image";
import { API_URL, invokeApi } from "../../helper/api";
import { GET } from "../../constant/commonConst";

// const ViewUnmounting = (props: any) => {
//   const { api, value, closeAddUpdate } = props;
//   const stateData = StateApi(false, {revalidateIfStale: true});
//   let stateList =
//     stateData?.data?.data?.length > 0
//       ? stateData.data.data?.map((item: any) => {
//         return { value: item.id, label: item.name };
//       })
//       : [];

//   return (
//     <Formik
//       initialValues={value}
//       onSubmit={(values: any, actions: any) => {
//         if (values.id) {
//           api.updateValue(values).then((result: any) => {
//             if (result?.status) {
//               closeAddUpdate();
//             }
//           });
//         } else {
//           api.create(values).then((result: any) => {
//             if (result?.status) {
//               closeAddUpdate();
//             }
//           });
//         }
//       }}
//     >
//       {({ values, isSubmitting, setFieldValue }) => (
//         <Form className="form-main">
//           <Dropdown
//             fieldName="stateId"
//             labelName="State"
//             options={stateList}
//             setFieldValue={setFieldValue}
//             gorupClass={"mb-3"}
//             values={values}
//             isRequired={true}
//             selectedValue={values.stateId}
//           />
//           <TextBox
//             fieldName={"name"}
//             placeholder={"Enter state name"}
//             className={"form-control"}
//             type={"text"}
//             labelName={"City name"}
//             value={value}
//             isRequired={true}
//           />
//           <Col lg="12" className="d-flex justify-content-end gap-2">
//             <Button
//               color="secondary"
//               outline
//        type="button"
//               onClick={() => closeAddUpdate()}
//             >
//               Cancel
//             </Button>
//             <Button color="secondary" solid type="submit">
//               {value?.id > 0 ? "Update" : "Submit"}
//             </Button>
//           </Col>
//         </Form>
//       )}
//     </Formik>
//   );
// };
// export { ViewUnmounting };
const ViewUnmounting = (props: any) => {
  const { value } = props;
  const imageUpload = JSON.parse(value.unmountingShot);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  useEffect(() => {
    const fetchImages = async () => {
      const urls: string[] = [];

      for (const image of imageUpload) {
        if (image?.url) {
          try {
            const response = await invokeApi(
              GET,
              API_URL + image.url,
              undefined,
              undefined,
              {
                responseType: "blob", // Ensure we get the response as a Blob
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result as string;
              urls.push(base64String); // Add the Base64 string to the array
              // Once all images are processed, update the state
              if (urls.length === imageUpload.length) {
                setImageUrls(urls);
              }
            };
            reader.readAsDataURL(response); // Convert Blob to Base64
          } catch (error) {
            console.log("Error fetching image:", error);
          }
        }
      }
    };

    fetchImages();
  }, [value.mountingShot]);
  return (
    <div className="printing-view">
      <div className="booking-num">
        <p>Booking No:</p>
        <Badge color="black" className="badge-num">
          {value.bookingNo}
        </Badge>
      </div>

      <div>
        <Row className="mb-3">
          <Col>
            <Card>
              <ul className="poster-list">
                <li>
                  <div className="poster-list-item">
                    <span>Task No:</span>
                    <strong>
                      <Badge className="badges">{value.taskNo}</Badge>
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Booking ID:</span>
                    <strong>{value.booking.bookingNo}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Unmounting Time:</span>
                    <strong>{value?.unmountingTime ? value.unmountingTime : "N/A"}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Unmounting Date:</span>
                    <strong>
                      {value?.unmountingDate !== null
                        ? new Date(value.unmountingDate).toLocaleDateString()
                        : "N/A"}
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Assign To:</span>
                    <strong>
                      {value?.assignee?.userName
                        ? value?.assignee?.userName
                        : "N/A"}
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Status:</span>
                    <strong>
                      <Badge className="badge bg-success rounded-pill">
                        {value.status !== null ? value.status : "N/A"}
                      </Badge>
                    </strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Media Name:</span>
                    <strong>{value.mediaItem?.name || "N/A"}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Media Quantity:</span>
                    <strong>{value.mediaItem?.qty || "N/A"}</strong>
                  </div>
                </li>
                <li>
                  <div className="poster-list-item">
                    <span>Media Dimensions:</span>
                    <strong>
                      {value.mediaItem?.height} x {value.mediaItem?.width}
                    </strong>
                  </div>
                </li>
              </ul>
            </Card>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Card className="">
              <CardBody>
                <div className="">
                  <h5>Unmounting Shots:</h5>
                  <Row className="mounting-shot-image">
                    {imageUrls.map((url: any) => (
                      <Col className="mb-3" md={3}>
                        <div className="mounting-images">
                          <img src={url} />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export { ViewUnmounting };
export const UploadImage = (props: any) => {
  const [activeTab, setActiveTab] = useState("2");
  const imageUpload = useUpload(1);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="media-upload-modal">
      <Nav tabs>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            URL Upload
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Image Upload
          </NavLink>
        </NavItem>
      </Nav>
      <Formik
        initialValues={{ url: "" }}
        onSubmit={(values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <TabContent activeTab={activeTab}>
              {/* <TabPane tabId="1">
                <FormGroup noMargin>
                  <Label for="url">URL</Label>
                  <Input
                    type="url"
                    name="url"
                    id="url"
                    placeholder="Enter URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.url}
                  />
                </FormGroup>

              </TabPane> */}
              <TabPane tabId="2">
                <FormGroup noMargin>
                  {/* <Label for="url">Upload Image</Label> */}
                  {imageUpload.imagePreviews?.length === 0 && (
                    <ImageUpload
                      imageUpload={imageUpload}
                      accept={"image/*"}
                      isDisabled={imageUpload.selectedImages?.length >= 4}
                    />
                  )}{" "}
                  {imageUpload.imagePreviews.map((preview: any, index: any) => (
                    <div
                      key={index}
                      className="images-item"
                      style={{
                        backgroundColor: preview.isPrimary ? "#52a9ff" : "",
                      }}
                    >
                      {preview?.path ? (
                        <>
                          <ProtectedImage value={preview?.path} />
                        </>
                      ) : (
                        <img
                          src={preview.url}
                          alt={`preview-${index}`}
                          onClick={() =>
                            imageUpload.handleSetPrimaryImage(index)
                          }
                          width={25}
                          height={25}
                          style={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                          }}
                        />
                      )}

                      <button
                        className="remove-box"
                        onClick={() => imageUpload.handleRemoveImage(index)}
                      >
                        {crossIcon.icon}
                      </button>
                    </div>
                  ))}
                </FormGroup>
              </TabPane>
            </TabContent>
            <div className="d-flex justify-content-end">
              <Button type="submit">
                {activeTab === "1" ? "Submit URL" : "Submit"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
