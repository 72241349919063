import { Button, Col, Input, Row } from "reactstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CityApi } from "../city";
import axios from "axios";
import { useEffect, useState } from "react";
import { locationSchema } from "./validation";
import { StateApi } from "../state";
import debounce from "lodash.debounce";
import { company, LocationIcon, stateLocation, zoneLocation } from "../../../constant/icons";
import SearchLocationInput from "../../../helper/searchGoogleHelper";
import { extractLocationInfo, useHelper } from "./helper";

interface PlaceResult {
  place_id: string;
  name: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
}
const AddUpdateLocation = (props: any) => {
  const { value, closeAddUpdate, searchedData , setSearchedData,viewMode} = props;
  const { setSelectedLocation, handleSubmit, location } = useHelper(props);
  console.log("locationX",location,"s",props?.SelectedLocationView)
  return (
    <>
      <Formik
        validationSchema={locationSchema}
        initialValues={value}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form className="form-main">
            
            {!viewMode&&<div className="form-group">
              
              <SearchLocationInput
                fieldName={"Search Location"}
                callback={(props: any) => {
                  console.log("propsGX",props)
                  setFieldValue("name", props.name);
                  setFieldValue("description", props.formatted_address);
                }}
                searchedData={searchedData}
                setSearchedData={setSearchedData}
                setSelectedLocation={setSelectedLocation}
              />{" "}
              <ErrorMessage
                name={"name"}
                component="span"
                className="invalid-feedback"
              />
            </div>}

            {location?.name && (
              <>
                <div className="location-details">
                  <div className="location-head">
                    <h5>
                      <span>{LocationIcon.icon}</span>
                      {location.name}
                    </h5>
                    <div className="lat-lang">
                      <span>
                        <strong>Lat : </strong> {location.latitude}
                      </span>
                      <span>
                        <strong>Lang : </strong>
                        {location.longitude}
                      </span>
                    </div>
                  </div>
                  <div className="location-data">
                    {location.state && (
                      <div>
                        {stateLocation.icon}
                        <span>{location.state}</span>
                      </div>
                    )}
                    <div>
                      {/* <strong>City:</strong> */}
                      {company.icon}
                      <span>{location.city}</span>
                    </div>
                    {location.zone && (
                      <div>
                        {zoneLocation.icon}
                        <span>{location.zone}</span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <Col lg="12" className="d-flex justify-content-end gap-2">
              <Button
                color="secondary"
                outline
                type="button"
                onClick={() => closeAddUpdate()}
              >
                Cancel
              </Button>
              <Button color="secondary" solid type="submit">
                {value?.id > 0 ? "Update" : "Submit"}
              </Button>
            </Col>
          </Form>
        )}
      </Formik>
    </>
  );
};
export { AddUpdateLocation };
