import Dashboard from "./pages/dashboard";
import City from "./pages/masters/city";
import State from "./pages/masters/state";
import Agency from "./pages/masters/agency";
import Location from "./pages/masters/location";
import LandLord from "./pages/masters/landlord";
import Zone from "./pages/masters/zone";
import MediaType from "./pages/masters/mediatype";
import Industry from "./pages/masters/industry";
import Booking from "./pages/booking";
import Printing from "./pages/printing";
import Mounting from "./pages/mounting";
import UnMounting from "./pages/unMounting";
import RoleList from "./pages/role";
import Series from "./pages/masters/series";
import LITtype from "./pages/masters/littype";
import Client from "./pages/masters/client";
import User from "./pages/user";
import MediaItems from "./pages/masters/items";
import MediaView from "./pages/masters/items/view";
import LeadGeneration from "./pages/leadGeneration";
import BusinessProposal from "./pages/businessProposal";
import RouteList from "./pages/masters/route";
import Material from "./pages/masters/material";
import Receipt from "./pages/Receipt";
import BookingView from "./pages/booking/view";
import Report from "./pages/report";
import LeadReport from "./pages/report/lead";
import ReceiptReport from "./pages/report/receipt";
import Payment from "./pages/Payment";
import BusinessReport from "./pages/report/businessProposal";
import PrintingReport from "./pages/report/printing";
import ReportHording from "./pages/report/MediaHording";
import LedgerList from "./pages/report/client";
import UnMountingReport from "./pages/report/unmounting";
import MountingReport from "./pages/report/mounting";
import LeadgerReport from "./pages/report/ledger";
import PptSettings from "./pages/masters/pptSettings";
import BookingReport from "./pages/report/booking";

export const routes = [
  { path: "/home", element: <Dashboard /> },
  { path: "/user", element: <User /> },
  // { path: "/role", element: <RoleList /> },
  { path: "/city", element: <City /> },
  { path: "/pptSettings", element: <PptSettings /> },
  { path: "/state", element: <State /> },
  { path: "/agency", element: <Agency /> },
  { path: "/zone", element: <Zone /> },
  { path: "/location", element: <Location /> },
  { path: "/landlord", element: <LandLord /> },
  { path: "/mediaType", element: <MediaType /> },
  { path: "/mediaSeries", element: <Series /> },
  { path: "/mediaLit", element: <LITtype /> },
  { path: "/mediaItem", element: <MediaItems /> },
  { path: "/mediaItem/:id", element: <MediaView /> },
  { path: "/industry", element: <Industry /> },
  { path: "/client", element: <Client /> },
  { path: "/leadGeneration", element: <LeadGeneration /> },
  { path: "/booking", element: <Booking /> },
  { path: "/booking/view", element: <BookingView /> },
  { path: "/printing", element: <Printing /> },
  { path: "/mounting", element: <Mounting /> },
  { path: "/unMounting", element: <UnMounting /> },
  { path: "/businessProposal", element: <BusinessProposal /> },
  { path: "/route", element: <RouteList /> },
  { path: "/material", element: <Material  /> },
  { path: "/receipt", element: <Receipt  /> },
  { path: "/payment", element: <Payment  /> },
  { path: "/replead", element: <LeadReport  /> },
  { path: "/report", element: <Report/> },
  { path: "/receiptreport", element: <ReceiptReport  /> },
  { path: "/businessquotation", element: <BusinessReport  /> },
  { path: "/printingreport", element: <PrintingReport  /> },
  { path: "/mediaReport", element: <ReportHording /> },
  { path: "/ledgerReport", element: <LeadgerReport /> },
  { path: "/mountingReport", element: <MountingReport /> },
  { path: "/unmountingReport", element: <UnMountingReport /> },
  { path: "/bookingReport", element: <BookingReport /> },
];
