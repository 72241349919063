import { Card, Col, FormGroup, Label, Row } from "reactstrap";
import ImageUpload from "../../masters/items/imageInput";
import ProtectedImage from "../../../components/common/image";
import { crossIcon } from "../../../constant/icons";

interface PptUploaderProps {
  label: string;
  setFieldValue: any;
  values: any;
  id?: string;
  imageUpload?: any;
  errors?: any;
  setFieldError?: any;
}

const PptUploader = ({
  label,
  setFieldValue,
  values,
  id,
  imageUpload,
  setFieldError,
  errors,
}: PptUploaderProps) => {
  return (
    <Col lg="6" className="media-item">
      <FormGroup>
        <Label>{label}</Label>
          {imageUpload.imagePreviews?.length === 0 ? (
            <ImageUpload
              imageUpload={imageUpload}
              accept={"image/*"}
              isDisabled={imageUpload.selectedImages?.length >= 4}
              hideText
              callback={(e: any) => {
                if (!e.target.value) {
                  setFieldError(
                    id,
                    `${id?.toLocaleUpperCase()} Image is Required`
                  );
                } else {
                  setFieldError(id, null);
                }
                setFieldValue(id, e?.target?.files);
              }}
            />
          ) : null}
          {
            imageUpload.imagePreviews && imageUpload.imagePreviews.length > 0 &&    <Card className="media-images">
            <div className="images-list">
              {imageUpload.imagePreviews.map((preview: any, index: any) => {
                return (
                  <div
                    key={index}
                    className="images-item"
                    style={{
                      backgroundColor: preview.isPrimary ? "#FFEE00" : "",
                    }}
                  >
                    {preview?.path ? (
                      <>
                        <ProtectedImage value={preview?.path} />
                      </>
                    ) : (
                      <img
                        src={preview.url}
                        alt={`preview-${index}`}
                        onClick={() => imageUpload.handleSetPrimaryImage(index)}
                        width={25}
                        height={25}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "cover",
                        }}
                      />
                    )}
                    <button
                      className="remove-box"
                      onClick={(e) => {
                        imageUpload.handleRemoveImage(index);
                        setFieldValue(id,null)
                      }}
                    >
                      {crossIcon.icon}
                    </button>
                  </div>
                );
              })}
            </div>
          </Card>
          }
          {errors[id as any] ? <small>{errors[id as any]}</small> : null}
     
      </FormGroup>
    </Col>
  );
};

export default PptUploader;
