import { Badge, Button, Col, Form, Label, Row } from "reactstrap";
import { useEffect, useState } from "react";
import moment from "moment";
import { Formik } from "formik";
import { jwtDecode } from "jwt-decode";
import { useApi } from "../../../helper/apiHook";
import { useUpload } from "../items/helper";
import PageLayout from "../../../components/layout/page";
import { uploadIcon } from "../../../constant/icons";
import { useAuth } from "../../../components/auth";
import { getValueByKey } from "../../../utils/generalUtils";
import ModalView from "../../../components/modal";
import { validationSchemaForPptSettings } from "./validation";
import PptUploader from "./PptUploader";

export const PPtSettingsAPI = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/ppt-settings", paging, undefined, dataLoad) };
};

export default function PptSettings() {
  const pptApi = PPtSettingsAPI(true);
  const frontImageUploader = useUpload(1);
  const lastImageUploader = useUpload(1);

  const [isModal, setIsModal] = useState(true);
  const [user, setUser] = useState<any>(null);

  const auth = useAuth();
  useEffect(() => {
    const token = getValueByKey("token")
      ? JSON.parse(getValueByKey("token")!)
      : null;

    const decoded: any = token ? jwtDecode(token) : null;
    setUser({
      userId: decoded?.userId,
      name: decoded?.name,
    });
  }, [auth?.user?.token]);

  useEffect(() => {
    if (pptApi?.data && pptApi?.data.length > 0) {
      frontImageUploader.setImagePreviews([
        {
          path: pptApi?.data[0].frontImage ?? "",
          id: "undefined",
          isPrimary: false,
        },
      ]);
      lastImageUploader.setImagePreviews([
        {
          path: pptApi?.data[0].lastImage ?? "",
          id: "undefined",
          isPrimary: false,
        },
      ]);
    }
  }, [pptApi?.data]);

  const initialValuesForPptSettings =
  {
    id: pptApi?.data && pptApi?.data[0]?.id ? pptApi?.data[0]?.id : "",
    frontImage:
      pptApi?.data && pptApi?.data[0]?.frontImage
        ? pptApi?.data[0]?.frontImage
        : "",
    lastImage:
      pptApi?.data && pptApi?.data[0]?.lastImage
        ? pptApi?.data[0]?.lastImage
        : "",
    uploadedBy:
      pptApi?.data && pptApi?.data?.name
        ? pptApi?.data?.name
        : user?.name,
    uploadedDate:
      pptApi?.data && pptApi?.data[0]?.uploadedDate
        ? pptApi?.data[0]?.uploadedDate
        : new Date(),
  }

  return (
    <>
      {/* <PageLayout
        item={
          <>
            <Button color="secondary" onClick={() => setIsModal(true)}>
              {uploadIcon.icon}PPT Settings
            </Button>
          </>
        }
      /> */}
      <ModalView
        modalView={isModal}
        modalName={"PPT Settings"}
        onModalDismiss={() => setIsModal(!isModal)}
      >
        <Formik
          initialValues={initialValuesForPptSettings}
          validationSchema={validationSchemaForPptSettings}
          onSubmit={(values: any, actions: any) => {
            const data = values.length > 0 ? values : initialValuesForPptSettings;
            
            values = {
              ...data,
              uploadedBy: user?.userId,
              uploadedDate: new Date(values.uploadedDate).toISOString(),
            };
            const formdata = new FormData();
            if (
              frontImageUploader.imagePreviews[0]?.id !== "undefined" &&
              frontImageUploader.selectedImages[0]
            ) {
              formdata.append(
                `frontImage`,
                frontImageUploader.selectedImages[0]
              );
            }
            if (
              lastImageUploader.imagePreviews[0]?.id !== "undefined" &&
              lastImageUploader.selectedImages[0]
            ) {
              formdata.append(`lastImage`, lastImageUploader.selectedImages[0]);
            }
            for (const key in values) {
              if (values[key] !== null && values[key] !== undefined) {
                formdata.append(key, values[key]);
              }
            }
            if (pptApi?.data?.length > 0) {
              pptApi.updateValue(formdata).then((result: any) => {
                if (result?.status) {
                  setIsModal(false);
                }
              });
            } else {
              pptApi.create(formdata).then((result: any) => {
                if (result?.status) {
                  setIsModal(false);
                }
              });
            }
          }}
        >
          {({ values, setFieldValue, handleSubmit, errors, setFieldError }) => {
            console.log("errors==>",errors)
            return (
              <Form className="form-main">
                <Row>
                  <PptUploader
                    label="Front Image"
                    id="frontImage"
                    setFieldError={setFieldError}
                    errors={errors}
                    imageUpload={frontImageUploader}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <PptUploader
                    label="Last Image"
                    id="lastImage"
                    setFieldError={setFieldError}
                    errors={errors}
                    imageUpload={lastImageUploader}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  {values?.uploadedBy ? (
                    <Col lg="6">
                      <Label>Uploaded By:</Label>
                      <Badge color="black" className="badge-num">
                        {values?.uploadedBy}
                      </Badge>
                    </Col>
                  ) : null}
                  {values?.uploadedDate ? (
                    <Col>
                      <Label>Uploaded Date</Label>
                      <Badge color="black" className="badge-num">
                        {moment(values?.uploadedDate).format("DD-MM-YYYY")}
                      </Badge>
                    </Col>
                  ) : null}
                </Row>
                <Col lg="12" className="d-flex justify-content-end gap-2 mt-2">
                  <Button
                    type="button"
                    onClick={(e: any) => {
                      handleSubmit(e);
                    }}
                    solid
                  >
                    Submit
                  </Button>
                </Col>
              </Form>
            );
          }}
        </Formik>
      </ModalView>
    </>
  );
}
