import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import AddCellTable from "../../../components/table/addCellTable";
import { DataType } from "ka-table";
import { ActionButtons, InputCell, MediaItem } from "../actions";


export default function FbpTable({ mediaInventory,bookingStartDate, setFieldValue }: any) {
  const dbId="FBM"
  const columns = [
    {
      key: "mediaItemId",
      title: "Media Item ID",
      style: { width:"15vw" },
      dataType: DataType.String,
      component: MediaItem,
    },
    { key: "city", title: "City", dataType: DataType.String, style: { width:"9vw" } },
    { key: "location", title: "Location", dataType: DataType.String, style: { width:"9vw" } },
    { 
      key: "width", 
      title: "Width", 
      dataType: DataType.Number,
      component: InputCell,
      style: { width:"8vw" }
    },
    { 
      key: "height", 
      title: "Height", 
      dataType: DataType.Number,
      component: InputCell,
      style: { width:"8vw" }
    },
    { key: "sqft", title: "Sq.ft", dataType: DataType.String, style: { width:"6vw" } },
    { 
      key: "qty", 
      title: "Qty", 
      classNames: "qty-box",
      dataType: DataType.Number,
      component: InputCell,
      style: { width: "7vw" }
    },
    { key: "rate", title: "Rate Per SQ.FT", 
      dataType: DataType.Number, 
      component: InputCell,
      style: { width: "9vw" }
    },
    { key: "amount", title: "Amount", dataType: DataType.String,style: { width: "7vw" } },
    {
      key: "addColumn",
      title: "",
      component: ActionButtons,
      style: { width: "5vw", textAlign: 'center' }
    },
  ];
  return (
    <>
      <AccordionItem>
        <AccordionHeader targetId={dbId}>
        FLEX BANNER MOUNTING
        </AccordionHeader>
        <AccordionBody accordionId={dbId}>
        <AddCellTable
          bookingStartDate={bookingStartDate}
          groupCode={dbId}
          updateFieldValue={setFieldValue}
          columns={columns}
          rows={mediaInventory}
        />
      </AccordionBody>
      </AccordionItem>
    </>
  );
}
