import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { leftSquareArrow, LocationIcon, searchIcon, uploadIcon } from "../../constant/icons";
import SelectStatus from "./components/SelectStatus";
import ProtectedImage from "../../components/common/image";
import UserLayout from "../../components/layout/UserLayout";
import { useApi } from "../../helper/apiHook";
import { getStatusActiveTab } from "../../utils/generalUtils";

export default function Dashboard(props: any) {
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation();
  const toggletab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (location?.state?.from) {
      setActiveTab(location?.state?.from)
    }

  }, [location])

  return (
    <div className="front-page">
      <div className="container">
        <UserLayout />
        {/* content data */}

        <div className="search-box d-flex justify-content-end my-3">
          <div className="search_box">
            <Button color="link" className="p-0">
              {searchIcon.icon}
            </Button>
            <input
              name="searchbox"
              placeholder="Search.."
              type="search"
              className="top-element"
            />
          </div>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggletab("1");
              }}
            >
              To-do
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggletab("2");
              }}
            >
              In-progress
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggletab("3");
              }}
            >
              Completed
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="mb-1" activeTab={activeTab}>
          {props?.children}
        </TabContent>
      </div>
    </div>
  );
}


export const MountingCardShow = (props: any) => {
  const mountingList = useApi(`/mountings/${props.activeBooking}`, true, {}, { revalidateIfStale: true })
  const navigate=useNavigate();

  return (
    <Row className="card-list">
      {
        mountingList?.data && mountingList?.data.length > 0 && mountingList?.data?.map((mountings: any) => {
          const parseMountingImages = mountings?.mountingImages ? JSON.parse(mountings?.mountingImages) : null;
          return <Col md={6} lg={4} sm={12} xl={3}
          // onClick={() => {
          //   navigate(`/mob-mounting/${mountings?.id}`)
          // }}
          >
            <div className="pb-2">
              <Button
                onClick={() => {
                  props.setActiveBooking(null)
                  navigate("/mob-mounting", {
                    state: {
                      from: getStatusActiveTab(mountings.status),
                      activeBooking: null, // Pass the ID here
                    },
                  });
                }}
                className="hamburger-btn p-0"
              >
                {leftSquareArrow.icon}
              </Button>
              <span style={{ marginLeft: "10px" }}>Booking No: {mountings?.bookingNo}</span>
            </div>
            <Card className="cursor-pointer">
              <ProtectedImage value={parseMountingImages?.url} />
              {/* <img src={CardImg} alt="Poster Image" /> */}
              <div className="card-content">
                <div className="content-head-box">
                  <div>
                    {/* <h3>Craft media Gandhinagar</h3> */}
                    <h3 className="d-flex gap-2"><span>{LocationIcon.icon}</span> {mountings?.mediaItem?.routeId ? mountings?.mediaItem?.route?.startLandmarkDescription : mountings?.mediaItem?.location?.name}</h3>
                  </div>
                  <div>
                    <span className="task-number">Task no: {mountings?.taskNo}</span>
                    <span className="poster-size-box">{`${mountings?.mediaItem?.width} * ${mountings?.mediaItem?.height}`} ft</span>
                  </div>
                </div>
                <div className="content-action">
                  <div className="status-box">
                    <SelectStatus value={mountings} api={mountingList} />
                    {
                      mountings?.status !== "mounted" ? <Link to={`/mob-mounting/${props.activeBooking}`}>
                        <Button
                          color="secondary"
                        >
                          {uploadIcon.icon} Add Mounting Photo
                        </Button>
                      </Link> : null
                    }
                    {/* <input type="file" capture="environment" accept="image/*" /> */}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        })
      }
    </Row>
  )
}
