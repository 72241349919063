import React, { useEffect } from "react";
import { Badge, Col, FormGroup, Label, Row } from "reactstrap";

export default function StartLatLon({
  setFieldValue,
  values,
  id,
  autoCompleteRef,
  setQuery,
  query,
  selectedLocation,
  fieldName,
  disabled
}: any) {
  useEffect(() => {
    if (selectedLocation) {
      const lat = selectedLocation.geometry?.location?.lat() || 0;
      const lng = selectedLocation.geometry?.location?.lng() || 0;
      // setFieldValue("name", selectedLocation.name);
      setFieldValue("startLatitude", lat);
      setFieldValue("startLongitude", lng);
      setFieldValue("startLandmarkDescription", selectedLocation.vicinity);
    }
  }, [selectedLocation]);
  return (
    <>
      <Row>
        <Col md={12}>
          <FormGroup>
            <div className="search-location-input">
              <label className="form-label">{fieldName}</label>
              <input
                ref={autoCompleteRef}
                id={fieldName || "name"}
                name={fieldName || "name"}
                className="form-control"
                onChange={(event) => setQuery(event.target.value)}
                placeholder="Search places ..."
                value={query}
                disabled={disabled}
              />
            </div>
            {/* <SearchLocationInput
                            id="location1"
                            fieldName={"Search Start Location"}
                            callback={(props: any) => {
                                const lat = props.geometry?.location?.lat() || 0;
                                const lng = props.geometry?.location?.lng() || 0;
                                setFieldValue("name", props.name);
                                setFieldValue("startLatitude", lat);
                                setFieldValue("startLongitude", lng);
                            }}
                            setSelectedLocation={setSelectedLocation}
                        /> */}
          </FormGroup>
        </Col>
        {values.startLatitude &&
          <Col md={12}>
            <FormGroup>
              <Label>Start Latitude - Longitude</Label>
              <div className="lat-long-badge">
                {values.startLatitude && (
                  <Badge>
                    {values.startLatitude}
                  </Badge>
                )}
                {values.startLongitude && (
                  <Badge>
                    {values.startLongitude}
                  </Badge>
                )}
              </div>
            </FormGroup>
          </Col>
        }
      </Row>
    </>
  );
}
