import { useApi } from "../../helper/apiHook";
import { useAddUpdate } from "../../helper/addUpdateHook";
import { useEffect, useState } from "react";
import PageLayout from "../../components/layout/page";
import { clickIcon } from "../../constant/icons";
import { columns } from "./columns";
import { DetailsRow } from "./details";
import { DataView } from "../../components/table/table";
import { useOutletContext } from "react-router-dom";

export const PrintingApi = (paging?: boolean, dataLoad: any = {}) => {
  return { ...useApi("/printingtasks", paging, undefined, dataLoad) };
};
export default function Printing(props: any) {
  // console.log(props, "|propspropsprops")
  const api = PrintingApi(true);
  const [openDetailPrinting, setOpenDetailPrinting] = useState<any>(null);
  const back:any = useOutletContext();
  useEffect(() => {
    api.mutate();
  }, []);
  useEffect(() => {
    if (back.backButton === null) {
      setOpenDetailPrinting(null);
    }
  },[back.backButton])
  const handleShowSIngalClick =(id:any)=>{
      back.setBackButton(true)
      setOpenDetailPrinting(id);
  }
  return (
    <>
      <PageLayout
        item={
          openDetailPrinting?.bookingNo && (
            <span className="booking-id">
              {openDetailPrinting?.bookingNo
                ? openDetailPrinting?.bookingNo
                : ""}
            </span>
          )
        }
        className={"booking-master-page"}
        View={
          openDetailPrinting?.bookingNo ? (
            <DetailsRow
              rowData={openDetailPrinting}
              apiPrinting={api}
              setOpenDetailPrinting={setOpenDetailPrinting}
              openDetailPrinting={openDetailPrinting}
            />
          ) : (
            <>
              <DataView
                columns={columns({ api: api.data, openAddEditFrom:handleShowSIngalClick })}
                // onRowDoubleClick={(event: any, extendedEvent: any) => {
                //   back.setBackButton(true)
                //   setOpenDetailPrinting(extendedEvent.childProps.rowData);
                // }}
                rows={api.data}
                currentPage={api.currentPage}
                rowCount={api.rowCount}
                limit={api.limit}
                updatePagination={api.updatePagination}
              />
              <div className="doubleclick_note">
                <p>
                  <span>{clickIcon.icon}</span> Double click to view Data
                </p>
              </div>
            </>
          )
        }
      />
    </>
  );
}
