import { Accordion, Button, Col, Row } from "reactstrap";
import { Form, Formik } from "formik";
import { validationSchema } from "./validation";
import PageLayout from "../../components/layout/page";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import Details from "./component/details";
import SoahTable from "./component/soah";
import PoahTable from "./component/poah";
import FpmpkTable from "./component/fpmpk";
import FbphTable from "./component/fbph";
import FbpTable from "./component/fbm";
import SoapkTable from "./component/soapk";
import { useApi } from "../../helper/apiHook";
import { Booking, MediaInventory } from "./dto";
import { uuid } from "../../utils/generalUtils";
import { GUJARAT_GST_CODE } from "../../constant/commonConst";
import { MediaItemsApi } from "../masters/items";
import { message } from "../../components/toast";

const AddUpdateBooking = (props: any) => {
  const { api, value, closeAddUpdate } = props;
  let body = undefined;
  let mediaItemsData = MediaItemsApi(false, { revalidateIfStale: true }, body,);
  const dtlAPI = useApi("/booking", false, {
    id: value.id,
  });

  useEffect(() => {
    dtlAPI.mutate();
  }, []);

  const [open, setOpen] = useState<any>("1");
  const toggle = (id: number | any) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };
  const seenIds = new Set();
  const filterDtlApi =
    dtlAPI.data?.mediaInventory &&
    dtlAPI.data?.mediaInventory.length > 0 &&
    dtlAPI.data?.mediaInventory.map((element: any) => {
      // if (seenIds.has(element.mediaItemId)) {
      //   return false;
      // }
      // seenIds.add(element.mediaItemId);
      if (seenIds.has(element.groupCode)) {
        return {...element};
      }
      seenIds.add(element.groupCode);
      return {...element};
    });
  return (
    <PageLayout
      View={
        !dtlAPI.isLoading && (
          <Formik
            validationSchema={validationSchema}
            initialValues={
              {
                id: dtlAPI.data.id,
                clientId: dtlAPI.data.clientId,
                userId: dtlAPI.data.userId,
                bookingDate: dtlAPI.data.bookingDate || new Date(),
                bookedByPersonEmail: dtlAPI.data.bookedByPersonEmail,
                bookedByPersonMobile: dtlAPI.data.bookedByPersonMobile,
                bookedByPersonName: dtlAPI.data.bookedByPersonName,
                description: dtlAPI.data.description,
                bookingStartDate: dtlAPI.data.bookingStartDate || new Date(),
                bookingNo: dtlAPI.data.bookingNo,
                proposalId: dtlAPI.data.proposalId,
                status: dtlAPI.data.status,
                billingAddress: dtlAPI.data.billingAddress,
                GSTIN: "",
                inventoryIds: [],
                mediaInventory: (!Array.isArray(filterDtlApi)
                  ? []
                  : (filterDtlApi as any[])
                )?.map((item: MediaInventory) => {
                  let endDate: Date = item.endDate
                    ? new Date(item.endDate)
                    : new Date();
                  let bookingStartDate: Date = dtlAPI.data.bookingStartDate
                    ? new Date(dtlAPI.data.bookingStartDate)
                    : new Date();

                  // Calculate the difference in milliseconds and then convert to days
                  let differenceInDays = Math.floor(
                    (endDate.getTime() - bookingStartDate.getTime()) /
                    (1000 * 60 * 60 * 24)
                  );
                  const sameMediaItemId =
                    dtlAPI.data?.mediaInventory &&
                      dtlAPI.data?.mediaInventory.length > 0
                      ? dtlAPI.data?.mediaInventory.filter(
                        (mediaitem: any) =>
                          mediaitem?.mediaItemId == item?.mediaItemId
                      )
                      : [];
                  const result =
                    sameMediaItemId && sameMediaItemId.length > 0
                      ? sameMediaItemId.reduce(
                        (acc: any, num: any) => {
                          const startDate = new Date(num.startDate);
                          const endDate = new Date(num.endDate);
                          if (
                            !acc.minStartDate ||
                            startDate < acc.minStartDate
                          ) {
                            acc.minStartDate = startDate;
                          }
                          if (!acc.maxEndDate || endDate > acc.maxEndDate) {
                            acc.maxEndDate = endDate;
                          }
                          const diffTime = acc.maxEndDate - acc.minStartDate;
                          const diffDays = Math.ceil(
                            diffTime / (1000 * 60 * 60 * 24)
                          );
                          acc.qty += num?.qty;
                          acc.amount += num?.amount;
                          acc.rate = acc.amount / acc.qty;
                          return { ...acc, diffDays };
                        },
                        {
                          qty: 0,
                          day: 0,
                          rate: 0,
                          minStartDate: null,
                          maxEndDate: null,
                          amount: 0,
                        }
                      )
                      : null;
                  const addAdditionDetails =
                    sameMediaItemId &&
                    sameMediaItemId.length > 0 &&
                    sameMediaItemId.map((addition: any) => {
                      const findMediaItem =
                        mediaItemsData?.data?.data &&
                        mediaItemsData?.data?.data?.length > 0 &&
                        mediaItemsData?.data?.data?.find(
                          (element: any) => element.id == addition?.mediaItemId
                        );
                      return {
                        ...addition,
                        city: findMediaItem?.city?.name,
                        size: `${findMediaItem?.width} X ${findMediaItem?.height}`,
                        location: findMediaItem?.location?.name,
                        sqft: findMediaItem?.width * findMediaItem?.height,
                        day: result?.diffDays,
                        tempRowId: uuid(),
                      };
                    });
                  return {
                    ...item,
                    day: result?.diffDays ?? differenceInDays,
                    qty: result?.qty ?? item?.qty,
                    rate: result?.rate ?? item?.rate,
                    endDate: result?.maxEndDate ?? endDate,
                    subMediaInventory:
                      sameMediaItemId.length !== 1 ? addAdditionDetails : [],
                    isHaveSubMediaInventory:
                      sameMediaItemId.length !== 1 ? true : false,
                    tempRowId: uuid(),
                  };
                }),
              } as Booking
            }
            onSubmit={(values: any, actions: any) => {
              debugger
              let popArray: any[] = [];
              const isMediaItemIdValid = values.mediaInventory.every(
                (item: any) =>
                  item.hasOwnProperty("mediaItemId") &&
                  item.mediaItemId !== null &&
                  item.mediaItemId !== ""
              );
              if (!isMediaItemIdValid) {
                message("error", "Please select media item for each inventory");
              } else {
                // Transform and filter the media inventory
                const filteredMediaInventory = values.mediaInventory
                  .map((x: any) => {
                    let {
                      tempRowId,
                      agencyAmount,
                      city,
                      mediaLitType,
                      mediaItem,
                      mediaSeries,
                      zonedesc,
                      location,
                      bookingId,
                      sqft,
                      size,
                      day,
                      updateDate,
                      updateBy,
                      createDate,
                      createBy,
                      mediaType,
                      isHaveSubMediaInventory,
                      ...mediaInventoryItem
                    } = x;
                    if (isHaveSubMediaInventory) {
                      x.subMediaInventory = x.subMediaInventory.map(
                        (zx: any) => {
                          let {
                            tempRowId,
                            agencyAmount,
                            city,
                            mediaLitType,
                            mediaSeries,
                            zonedesc,
                            location,
                            bookingId,
                            sqft,
                            size,
                            day,
                            updateDate,
                            updateBy,
                            createDate,
                            createBy,
                            mediaType,
                            isHaveSubMediaInventory,
                            mediaItem,
                            ...mediaInventoryItemX
                          } = zx;
                          return mediaInventoryItemX;
                        }
                      );
                      popArray = popArray.concat(x.subMediaInventory);
                      return null;
                    } else {
                      let { subMediaInventory, ...mediaInventoryItemRock } =
                        mediaInventoryItem;
                      return mediaInventoryItemRock;
                    }
                  })
                  .filter((item: any) => item !== null); // Filter out `null` values

                // Combine the filtered media inventory with popArray
                const combinedArray = popArray.concat(filteredMediaInventory);
                values.mediaInventory = combinedArray;
                const { tempRowId, bookingNo, GSTIN, ...valuesX } = values;
                delete values.GSTIN;
                if (values.id) {
                  api.updateValue(valuesX).then((result: any) => {
                    if (result?.status) {
                      closeAddUpdate();
                    }
                  });
                } else {
                  delete values.inventoryIds;
                  api.create(values).then((result: any) => {
                    if (result?.status) {
                      closeAddUpdate();
                    }
                  });
                }
              }
            }}
          >
            {({ setFieldValue, values }) => {
              const SubTotalAmount =
                Math.round(
                  (values.mediaInventory.reduce(
                    (acc, item) => acc + (item.amount || 0),
                    0
                  ) +
                    Number.EPSILON) *
                  100
                ) / 100;
              const igstAmount =
                Math.round((SubTotalAmount * 0.18 + Number.EPSILON) * 100) /
                100;
              const cgstAmount =
                Math.round((SubTotalAmount * 0.09 + Number.EPSILON) * 100) /
                100;
              const sgstAmount =
                Math.round((SubTotalAmount * 0.09 + Number.EPSILON) * 100) /
                100;
              const isGujaratGstNo = values?.GSTIN
                ? values?.GSTIN?.substring(-2) == GUJARAT_GST_CODE
                  ? true
                  : false
                : false;
              const totalAmount = Math.round(
                ((isGujaratGstNo
                  ? SubTotalAmount + igstAmount
                  : SubTotalAmount + cgstAmount + sgstAmount) +
                  Number.EPSILON) *
                100
              ) / 100

              return (
                <Form className="form-main add-booking">
                  <Details
                    proposal={false}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <Accordion open={open} toggle={toggle}>
                    <SoahTable
                      bookingStartDate={values.bookingStartDate}
                      setFieldValue={setFieldValue}
                      mediaInventory={values.mediaInventory}
                    />
                    <PoahTable
                      bookingStartDate={values.bookingStartDate}
                      setFieldValue={setFieldValue}
                      mediaInventory={values.mediaInventory}
                    />
                    <FbphTable
                      bookingStartDate={values.bookingStartDate}
                      setFieldValue={setFieldValue}
                      mediaInventory={values.mediaInventory}
                    />
                    <FbpTable
                      bookingStartDate={values.bookingStartDate}
                      setFieldValue={setFieldValue}
                      mediaInventory={values.mediaInventory}
                    />
                    <SoapkTable
                      bookingStartDate={values.bookingStartDate}
                      setFieldValue={setFieldValue}
                      mediaInventory={values.mediaInventory}
                    />
                    <FpmpkTable
                      bookingStartDate={values.bookingStartDate}
                      setFieldValue={setFieldValue}
                      mediaInventory={values.mediaInventory}
                    />
                  </Accordion>
                  <Row className="add-booking-footer">
                    <Col>
                      <div className="total-amount">
                        <label>Amount: {SubTotalAmount}</label>
                        {isGujaratGstNo ? (
                          <label>
                            IGST<strong> (18%):</strong> {igstAmount}
                          </label>
                        ) : (
                          <>
                            <label>
                              CGST<span>(9%):</span> {cgstAmount}
                            </label>
                            <label>
                              SGST<span>(9%):</span> {sgstAmount}
                            </label>
                          </>
                        )}

                        <label>
                          Total amount :
                          <strong>
                            {" "}
                            {totalAmount}
                          </strong>
                        </label>
                      </div>
                    </Col>
                    <Col lg="12" className="d-flex justify-content-end gap-2">
                      <Button
                        color="secondary"
                        outline
                        type="button"
                        onClick={closeAddUpdate}
                      >
                        Cancel
                      </Button>
                      <Button color="secondary" solid type="submit" onClick={() =>
                        setFieldValue("totalAmount", totalAmount)
                      }>
                        {value?.id > 0 ? "Update" : "Save"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        )
      }
    />
  );
};

export { AddUpdateBooking };
