import { ActionType, useTableInstance } from "ka-table";
import {
  Button,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import { MediaItemsApi } from "../masters/items";
import Select from "react-select";
import { useEffect, useMemo, useRef, useState } from "react";
import { deleteIcon, downloadIcon, extendbookingIcon, hoardingIcon, LocationIcon, printIcon } from "../../constant/icons";
import { uuid } from "../../utils/generalUtils";
import { AgencyApi } from "../masters/agency";
import {
  comingSoon,
  confirmBox,
  message,
  messageAlert,
} from "../../components/toast";
import { RouteApi } from "../masters/route";
import { CityApi } from "../masters/city";
import { LocationApi } from "../masters/location";
import { MaterialApi } from "../masters/material";
import { Field } from "formik";
import SoahTableExtent from "./component/tableextent";
import DatePicker from "react-datepicker";
import { getDate } from "../../utils/dateUtils";

export const DateView = (props: any) => {
  try {
    if (!props) {
      return "";
    }
    return new Date(props).toLocaleDateString();
  } catch { }
  return props;
};
export const DateEdit=(props:any)=>{
  const {
    rowData,
    column
  } = props;
  return <Field
  
            component={DatePicker}
            placeholderText={"DD/MM/YYYk"}
            dateFormat={"dd/MM/yyyy"}
            className="form-control"
            name={props?.column?.key}
            selected={rowData[props?.column?.key] ? getDate(rowData[props?.column?.key]) : ""}
            onChange={(val:any)=>{
              if(column?.callback){
                column?.callback(new Date(val).toISOString(),rowData,props?.column?.key);
              }
            }} 
            portalId="root"
            // withPortal={true}

  />
}
const RemoveControl = (props: any) => {
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  //--------------------------current Row----------------------------------------
  return (
    <>
      <Button
        color="link"
        onClick={async () => {
          const response = await confirmBox("you want to delete this row?");
          if (response) {
            const removeInventoryId = rowAllData && rowAllData.length > 0 && rowAllData.find((element: any) => element.tempRowId == rowKeyValue)
            updateFieldValue(
              "mediaInventory",
              (rowAllData as any[]).filter((x) => x.tempRowId != rowKeyValue)
            );
            updateFieldValue("inventoryIds", rowAllData?.inventoryIds && rowAllData?.inventoryIds.length > 0 ? [...rowAllData.inventoryIds, removeInventoryId?.id] : [removeInventoryId?.id])
          }
        }}
        className="text-danger delete-btn"
        id="removeTooltip"
      >
        {deleteIcon.icon}
      </Button>
    </>
  );
};
const CheckboxControl = (props: any) => {
  const {
    column,
    rowData,
    rowKeyValue,
    value,
    rowAllData,
    updateFieldValue,
    label,
    onChange,
    type,
    id
  } = props;
  return (
    <FormGroup check className="media-radio">
      <Input type="checkbox" id={id} checked={value} onChange={onChange} />
      <Label check title={label} htmlFor={id}>
        {" "}
        {label === "Print" ? (
          <>{printIcon.icon}</>
        ) : label === "Mounting" ? (
          <>{hoardingIcon.icon}</>
        ) : (
          label
        )}
      </Label>
    </FormGroup>
    // <FormGroup check key={value} className="media-radio">
    //   <Input
    //     onChange={onChange}
    //     type="radio"
    //     name="type"
    //     id={value}
    //     className={"form-check-input"}
    //     checked={value}
    //   />
    //   <Label check htmlFor={value}>
    //     {/* <Label check> {}</Label> */}
    //     {label === "Print" ?  <span>{printIcon.icon}</span> : label === "Mounting"?  <span>{hoardingIcon.icon}</span> : <></>}

    //   </Label>
    // </FormGroup>
  );
};
export const ActionButtons = (props: any) => {
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } = props;

  //--------------------------Include Print----------------------------------------
  let includePrint = (rowAllData as any[]).find((x) => x.groupCode == "FBPH" && x.mediaItemId == rowData?.mediaItemId);
  includePrint = rowData?.mediaItemId > 0 ? (includePrint ? true : false) : false;
  //--------------------------Include Mounting----------------------------------------
  let includeMounting = (rowAllData as any[]).find((x) => x.groupCode == "FBM" && x.mediaItemId == rowData?.mediaItemId);
  includeMounting = rowData?.mediaItemId > 0 ? (includeMounting ? true : false) : false;
  //--------------------------current Row----------------------------------------
  let currentRow = (rowAllData as any[]).find((x) => x.tempRowId == rowKeyValue);
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  return (
    <div className="plus-cell-button">
      {["POAH", "SOAH"].includes(rowData?.groupCode) && (
        <>
          <CheckboxControl
            id={`Print${rowKeyValue}`}
            label={`Print`}
            {...props}
            value={includePrint}
            onChange={async (e: any) => {
              if (e.target.checked) {
                if (!rowData?.mediaItemId || rowData?.mediaItemId <= 0) {
                  messageAlert("Please select a media item first.");
                  return;
                }
                (rowAllData as any[]).push({
                  ...currentRow,
                  groupCode: "FBPH",
                  tempRowId: uuid(),
                });
                updateFieldValue("mediaInventory", rowAllData);
              }
            }}
          />
          <CheckboxControl
            id={`Mounting${rowKeyValue}`}
            label={`Mounting`}
            {...props}
            value={includeMounting}
            onChange={(e: any) => {
              if (e.target.checked) {
                if (!rowData?.mediaItemId || rowData?.mediaItemId <= 0) {
                  messageAlert("Please select a media item first.");
                  return;
                }
                (rowAllData as any[]).push({
                  ...currentRow,
                  groupCode: "FBM",
                  tempRowId: uuid(),
                });
                updateFieldValue("mediaInventory", rowAllData);
              }
            }}
          />
        </>
      )}
      {
        rowData?.isHaveSubMediaInventory ?  
          ["SOAH","POAH","SOAPK"].includes(rowData?.groupCode) ? <Button
          color="link"
          onClick={() => {
            if(column?.callBack){
              column?.callBack(rowData)
            }
          }}
          className="text-secondary location-button"
          id="addTooltip"
        >
          {/* {LocationIcon.icon} */}
          {extendbookingIcon.icon}
        </Button>:null
        :<RemoveControl {...props} />
      }
    </div>
  );
};

export const InputCell = (props: any) => {
  const {
    column,
    rowData,
    rowKeyValue,
    value,
    rowAllData,
    updateFieldValue,
    bookingStartDate,
  } = props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  useEffect(() => {
    if (["SOAPK", "SOAH", "FPMPK"].includes(rowData?.groupCode) && ["qty", "rate", "day"].includes(column.key)) {
      if (["day"].includes(column.key)) {
        let tempDate = new Date(bookingStartDate);
        try {
          tempDate.setDate(tempDate.getDate() + Number(currentRow.day));
          if(!column?.isEditable){
            currentRow.endDate = tempDate.toISOString
              ? tempDate?.toISOString()
              : tempDate;
          }
        } catch { }
      }
      currentRow.amount =currentRow.qty * (currentRow.rate / 30) * Number(currentRow.day);
      if (isNaN(currentRow.amount)) {
        currentRow.amount = 0;
      } else {
        currentRow.amount = Number(currentRow.amount.toFixed(3));
      }
    } else if (["POAH"].includes(rowData?.groupCode)) {
      if (["width", "height"].includes(column.key)) {
        currentRow.sqft = currentRow.width * currentRow.height;
      } else if (["rate", "agencyRate", "qty"].includes(column.key)) {
        currentRow.amount = currentRow.rate * currentRow.qty;
        if (isNaN(currentRow.amount)) {
          currentRow.amount = 0;
        } else {
          currentRow.amount = Number(currentRow.amount.toFixed(3));
        }
        currentRow.agencyAmount = currentRow.agencyRate * currentRow.qty;
        if (isNaN(currentRow.agencyAmount)) {
          currentRow.agencyAmount = 0;
        } else {
          currentRow.agencyAmount = Number(currentRow.agencyAmount.toFixed(3));
        }
      }
    } else if (
      ["FBM", "FBPH"].includes(rowData?.groupCode) &&
      ["width", "height", "qty", "rate"].includes(column.key)
    ) {
      if (rowData.groupCode === "FBM") {
        currentRow.rate = 2; // Fixed rate for FBM
      } 
      currentRow.sqft = currentRow.width * currentRow.height;
      currentRow.amount = currentRow.sqft * currentRow.qty * currentRow.rate;
    }
      if(!column?.isExtentEditable){
        updateFieldValue("mediaInventory", rowAllData);
      }
  }, [value]);
  const inputValue = (column.title === "Rate Per SQ.FT" && (value === undefined || value === null)) ? 2 : value;
  return (
    <>
    {
      rowData?.isHaveSubMediaInventory ?<NonEditableCell {...props}/>:  <Input
      id={`${rowKeyValue}${column.key}`}
      value={inputValue}
      type="number"
      onChange={(e: any) => {
        currentRow[column.key] = Number(e.target.value);
        if(column?.isExtentEditable){
          column?.callback(rowAllData);
       }else{
         updateFieldValue("mediaInventory", rowAllData);
       }
      }}
    />
    }
    </>
  );
};
export const MediaItem = (props: any) => {
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let body = undefined;
  if (["POAH", "SOAH"].includes(rowData?.groupCode)) {
    body = {
      hasAgency: ["POAH"].includes(rowData?.groupCode),
    };
  }
  let mediaItemsData = MediaItemsApi(false, { revalidateIfStale: true }, body);
  const findFBPH = ["FBPH", "FBM"].includes(rowData?.groupCode);
  if (findFBPH) {
    mediaItemsData = { ...mediaItemsData, data: { ...mediaItemsData, data: mediaItemsData?.data?.data && mediaItemsData?.data?.data?.length > 0 ? mediaItemsData?.data?.data.filter((element: any) => !element.routeId) : [] } }
  }
  let mediaItemsList: any[] = useMemo(
    () =>{
      const getMediaList = mediaItemsData?.data?.data?.length > 0
        ? mediaItemsData.data.data.map((item: any) => {
          return { value: item.id, label: item.name, data: item };
        })
        : [];
        if(!column?.isDisabled && !rowData?.id){
          const rowAllgetAllFilter = rowAllData && rowAllData.length > 0 ? rowAllData.filter((element:any)=>element?.groupCode == rowData?.groupCode):null;
          const filterMediaItem = getMediaList.filter((element:any)=>{
            const findMediaisIncluded = rowAllgetAllFilter.find((elements:any)=> {
              return  elements?.mediaItemId?.toString().includes(element?.value) && elements.groupCode == rowAllData?.groupCode
            });
            return element?.value !== findMediaisIncluded?.mediaItemId
          });
          return filterMediaItem;
        }
        return getMediaList;
      },
    [mediaItemsData,rowAllData,value,column.isDisabled]
  );

  // rowAllData && rowAllData.length > 0 && rowAllData.filter((element:any)=>{
  //   console.log("element===>",element)
  //   return element?.groupCode == rowData?.groupCode
  // }).find((findelement:any)=>{
  //   console.log("findelement---<",findelement)
  //   return findelement?.includes(value)
  // })
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  useEffect(() => {
    if (value) {
      let findItem = mediaItemsData?.data?.data?.find(
        (x: any) => x.id == value
      );
        if (findItem) {
          currentRow.size = `${findItem.width} X ${findItem.height}`;
          currentRow.height = findItem.height;
          currentRow.width = findItem.width;
          currentRow.sqft = findItem.width * findItem.height;
          if(!column?.isDisabled){
            if (!currentRow.qty) {
              currentRow.qty = findItem.qty;
            }
            if (!currentRow.rate) {
              currentRow.rate = findItem.rateAgency;
            }
          }
          currentRow.location = findItem?.location?.name;
          currentRow.city = findItem?.city?.name;
          updateFieldValue("mediaInventory", rowAllData);
        }
      
    }
  }, [value, mediaItemsData.isLoading,column]);
  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={
        value ? mediaItemsList.find((x: any) => x.value == value) : undefined
      }
      className={"className"} //form-control
      options={mediaItemsList}
      isDisabled={column?.isDisabled}
      // isClearable={column?.isDisabled}
      onChange={(opt: any) => {
        currentRow.mediaItemId = opt?.value ? opt.value : undefined;
        if (!currentRow?.mediaItemId) {
          currentRow.size = ``;
          currentRow.height = ``;
          currentRow.width = ``;
          currentRow.sqft = ``;
          currentRow.location = ``;
          currentRow.city = ``;
        }
        updateFieldValue("mediaInventory", rowAllData);

      }}
    />
  );
};
export const AgencyID = (props: any) => {
  const agencyApiData = AgencyApi(false);
  let agencyApiList: any[] = useMemo(
    () =>
      agencyApiData?.data?.data?.length > 0
        ? agencyApiData.data?.data?.map((item: any) => {
          return { value: item.id, label: item.name };
        })
        : [],
    [agencyApiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={agencyApiList.find((x: any) => x.value == value)}
      className={"className"} //form-control
      options={agencyApiList}
      // isClearable={true}
      onChange={(opt: any) => {
        currentRow.agencyId = opt.value;
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};

export const City = (props: any) => {
  const apiData = CityApi(false);
  let apiDataList: any[] = useMemo(
    () =>
      apiData?.data?.data?.length > 0
        ? apiData.data?.data?.map((item: any) => {
          return { value: item.id, label: item.name };
        })
        : [],
    [apiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={apiDataList.find((x: any) => x.value == value)}
      className={"className"} //form-control
      options={apiDataList}
      // isClearable={true}
      onChange={(opt: any) => {
        currentRow.cityId = opt?.value;
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};
export const Location = (props: any) => {
  const apiData = LocationApi(false);
  let apiDataList: any[] = useMemo(
    () =>
      apiData?.data?.data?.length > 0
        ? apiData.data?.data?.map((item: any) => {
          return { value: item.id, label: item.name };
        })
        : [],
    [apiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={apiDataList.find((x: any) => x.value == value)}
      className={"className"} //form-control
      options={apiDataList}
      // isClearable={true}
      onChange={(opt: any) => {
        currentRow.locationId = opt?.value;
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};
export const MaterialID = (props: any) => {
  const apiData = MaterialApi(false, {
    revalidateIfStale: true
  });
  let apiDataList: any[] = useMemo(
    () =>
      apiData?.data?.data?.length > 0
        ? apiData.data?.data?.map((item: any) => {
            return { value: item.id, label: item.name, data: item };
          })
        : [],
    [apiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
   // Set the default value and rate when the component mounts
   useEffect(() => {
    if (apiDataList.length > 0 && !currentRow.materialId) {
      const firstItem = apiDataList[0];
      currentRow.materialId = firstItem.value;
      currentRow.rate = firstItem.data.rate; // Set the rate based on the first item
      updateFieldValue("mediaInventory", rowAllData);
    }
  }, [apiDataList, currentRow, updateFieldValue, rowAllData]);

  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"MaterialItem"}
      id={`${rowKeyValue}MaterialItem`}
      value={value ? apiDataList.find((x: any) => x.value == value) : apiDataList[0]} // Set first item as default
      className={"className"} //form-control
      options={apiDataList}
      onChange={(opt: any) => {
        currentRow.rate = opt?.data.rate
        currentRow.materialId = opt?.value;
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};
export const RouteID = (props: any) => {
  const apiData = RouteApi(false);
  let apiDataList: any[] = useMemo(
    () =>
      apiData?.data?.data?.length > 0
        ? apiData.data?.data?.filter((element: any) => element?.MediaItem?.length > 0).map((item: any) => {
          return { value: item.id, label: item.name };
        })
        : [],
    [apiData]
  );
  const { column, rowData, rowKeyValue, value, rowAllData, updateFieldValue } =
    props;
  let currentRow = (rowAllData as any[]).find(
    (x) => x.tempRowId == rowKeyValue
  );
  if (!currentRow) {
    currentRow = {
      tempRowId: rowKeyValue,
    };
  }
  useEffect(() => {
    if (value) {
      let findItem = apiData?.data?.data?.find((x: any) => x.id == value);
      if (
        Array.isArray(findItem?.MediaItem) &&
        findItem?.MediaItem.length > 0
      ) {
        findItem = findItem.MediaItem[0];
        currentRow.mediaLitType = findItem?.mediaLitType?.name;
        currentRow.mediaType = findItem?.mediaType?.name;
        currentRow.zonedesc = findItem?.zone?.name;
        currentRow.mediaSeries = findItem?.mediaSeries?.name;
        currentRow.mediaItemId = findItem?.id;
        currentRow.size = `${findItem.width} X ${findItem.height}`;
        currentRow.height = findItem.height;
        currentRow.width = findItem.width;
        currentRow.sqft = findItem.width * findItem.height;
        if (!currentRow.qty) {
          currentRow.qty = findItem.qty;
        }
        if (!currentRow.rate) {
          currentRow.rate = findItem.rateAgency;
        }
        updateFieldValue("mediaInventory", rowAllData);
      }
    }
  }, [value, apiData.isLoading]);
  return (
    <Select
      menuPlacement="auto"
      menuPortalTarget={document.body}
      name={"mediaItem"}
      id={`${rowKeyValue}mediaItem`}
      value={apiDataList.find((x: any) => x.value == value)}
      className={"className"} //form-control
      options={apiDataList}
      // isClearable={true}
      onChange={(opt: any) => {
        currentRow.routeId = opt?.value;
        if (!currentRow?.routeId) {
          currentRow.mediaLitType = "";
          currentRow.mediaType = "";
          currentRow.zonedesc = "";
          currentRow.mediaSeries = "";
          currentRow.mediaItemId = "";
          currentRow.size = "";
          currentRow.height = "";
          currentRow.width = "";
          currentRow.sqft = "";
          currentRow.qty = "";
          currentRow.rate = "";
          currentRow.materialId = "";

        }
        updateFieldValue("mediaInventory", rowAllData);
      }}
    />
  );
};

export const NonEditableCell = (props: any) => {
  const { rowData, column } = props;
  return <span>{rowData[column.key]}</span>;
};

export const DownloadPdf = (props: any) => {
  const { printPDF, tooltipOpen, toggleTooltip } = props
  return(
    <>
      <Button
        type="button"
        color="link"
        onClick={printPDF}
        id="donwloadtooltip"
      >
        {downloadIcon.icon}
      </Button>
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        target="donwloadtooltip"
        toggle={toggleTooltip}
      >
        Download Pdf
      </UncontrolledTooltip>
    </>
  )
}
