import { imageIcon } from "../../../constant/icons";

interface ImageUploadProps {
  accept: string;
  imageUpload: any;
  isMultipal?: string;
  isDisabled?: boolean;
  hideText?:boolean;
  callback?:any;
}

const ImageUpload = ({
  imageUpload,
  accept,
  isMultipal,
  isDisabled,
  hideText,
  callback
}: ImageUploadProps) => {
  return (
    <>
      {" "}
      <div className="dropzone">
        <label className="dropzone-container">
          <div className="file-icon">{imageIcon.icon}</div>
          <div className="dropzone-title">          
            Drag and drop your Image or
            <span className="browse"> browse</span> your Image            
            {hideText ? null :<span className="browse d-block mt-1">Select 4 Images</span>}
          </div>
        </label>
        <input
          type="file"
          accept={accept}
          multiple={isMultipal !== "1"}
          onChange={(e:any)=>{
            imageUpload.handlefileChange(e)
            if(callback){
              callback(e);
            }
          }}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default ImageUpload;
