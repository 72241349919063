import React, { useState } from 'react'
import { useApi } from '../../../helper/apiHook';
import Dashboard, { MountingCardShow } from '../../mobileView';
import { TabPane } from 'reactstrap';
import UsermobMountingDataView from '../../mobileView/components/UsermobMountingDataView';
import { components } from 'react-select';
import { useLocation } from 'react-router-dom';

export const GetAllMounting = (paging?: boolean, dataLoad: any = {},) => {
  return { ...useApi("/mountings/getAll", paging, undefined, dataLoad) };
};
const MountingMobileView = () => {
  const location = useLocation();

  const mountingList = GetAllMounting(true);
  const { activeBooking } = location.state || {}; // Get activeBooking from state
  const [selectedBooking, setSelectedBooking] = useState(activeBooking); // Initialize with passed activeBooking

  return (
    <Dashboard mountingList={mountingList} >
      {["waiting-for-creative", "mounting-pending", "mounted"].map((element, index) => {
        return <TabPane tabId={(index + 1).toString()}>
          {selectedBooking ? (
            <MountingCardShow activeBooking={selectedBooking} setActiveBooking={setSelectedBooking} filter={element} />
          ) : (
            <UsermobMountingDataView setActiveBooking={setSelectedBooking} />
          )}
        </TabPane>
      })}
    </Dashboard>
  )
}

export default MountingMobileView