import {
  Button,
  FormGroup,
  Input,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import { deleteIcon } from "../../constant/icons";
import { useState } from "react";

const RemoveControl = (props: any) => {
  const { column, rowData } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <>
      <Button
        color="link"
        onClick={() => {
          if(column.callBack){
            column.callBack({ data: rowData, status: "delete" });
          }
        }}
        className="text-danger"
        id="removeTooltip"
      >
        {deleteIcon.icon}
      </Button>
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        target="removeTooltip"
        toggle={toggleTooltip}
      >
        Remove
      </UncontrolledTooltip>
    </>
  );
};

export { RemoveControl };
