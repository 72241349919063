// ProtectedImage.js
import React, { useState, useEffect } from "react";
import { getValueByKey } from "../../utils/generalUtils";
import { API_URL, fetcher, invokeApi } from "../../helper/api";
import { GET } from "../../constant/commonConst";

const ProtectedImage = ({ className, value ,callBack}: any) => {
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    const fetchImage = async () => {
      if (value) {
        try {
          const response = await invokeApi(
            GET,
            API_URL + value,
            undefined,
            undefined,
            {
              responseType: "blob", // Ensure we get the response as a Blob
            }
          );
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageUrl(reader.result as string); // Set the Base64 string as image URL
          };
          reader.readAsDataURL(response); // Convert Blob to Base64
        } catch (error) {
          
        }
      }
    };

    fetchImage();
  }, [value]);

  return (
    <>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="Protected"
          // style={{width: "100px", height: "100px" }}
          className={className}
          onClick={(props)=>{
            if(callBack){callBack(props)}}}
        />
      ) : (
        <h4 className="skeleton" style={{ width: "100px", height: "100px" }}>
          {/* loading */}
        </h4>
      )}
    </>
  );
};

export default ProtectedImage;
