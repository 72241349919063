import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators";
import { Badge } from "reactstrap";
import { DetailControl } from "./detail";
import { EditControl } from "./edit";
import { RemoveControl } from "./remove";
import GeneratePdf from "../../pages/booking/pdfGenerate/generatePdf";

const ActionButton = (props: any) => {
  return (
    <div className="action_btn table-action">
      <EditControl {...props} />
      <RemoveControl {...props} />
    </div>
  );
};

const Status = () => {
  return <Badge className="rounded-pill" color="success">Done</Badge>;
};

const LinkClickable = (props: any) => {
  const { rowData } = props;
  let urls: string[] = [];
  // Attempt to parse the JSON string
  try {
    const jsonParseCreativeUrl = JSON.parse(rowData.creativeUrl);
    urls = jsonParseCreativeUrl.filter((creativeurl: any) => creativeurl.tagname !== "image").map((mapelement: any) => mapelement.url);
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }
  return (
    <ul className="creative-link-list">
      {urls?.length > 0 ? (
        urls.map((url: string, index: number) => (
          <li key={index}>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              {`Crop-${index + 1}`}
            </a>
          </li>
        ))
      ) : (
        <p>No URL Provided</p>
      )}
    </ul>
  );
};

const ActionButtonView = (props: any) => {
  return (
    <div className="action_btn table-action">
      {/* <DetailControl {...props} /> */}
      <EditControl {...props} />
      <RemoveControl {...props} />
    </div>
  );
};

const ActionButtonNewView = (props: any) => {
  return (
    <div className="action_btn table-action">
      <GeneratePdf {...props} />
      {!props.column.isBookingReport &&
        <>
          <EditControl {...props} />
          <RemoveControl {...props} />
        </>
      }
    </div>
  );
};


const viewButton = (props: any) => {
  const {
    disabled,
    column,
    dispatch,
    isDetailsRowShown,
    rowData,
    rowKeyValue,
    value,
    message,
  } = props;
  return (
    <div className="action_btn table-action">
      <h6
        onClick={() => {
          dispatch(
            isDetailsRowShown
              ? hideDetailsRow(props.rowKeyValue)
              : showDetailsRow(props.rowKeyValue)
          );
        }}
      >
        view
      </h6>
    </div>
  );
};

export { ActionButton, ActionButtonNewView, ActionButtonView, viewButton, Status, LinkClickable };
