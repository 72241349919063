import React, { useEffect } from "react";
import { Badge, Col, FormGroup, Label, Row } from "reactstrap";
import { extractLocationInfo } from "../../location/helper";

export default function EndLatLon({
  setFieldValue,
  selectedLocation,
  values,
  id,
  autoCompleteRef,
  setQuery,
  query,
  fieldName,
  disabled
}: any) {
  useEffect(() => {
    if (selectedLocation) {
      const lat = selectedLocation.geometry?.location?.lat() || 0;
      const lng = selectedLocation.geometry?.location?.lng() || 0;

      const { city, state, zone } = extractLocationInfo(
        selectedLocation.address_components
      );
      setFieldValue("cityId", city);
      setFieldValue("stateId", state);
      setFieldValue("endLatitude", lat);
      setFieldValue("endLongitude", lng);
      setFieldValue("endLandmarkDescription", selectedLocation.vicinity);
    }
  }, [selectedLocation]);
  return (
    <>
      <Row id={`row${id}`}>
        <Col md={12}>
          <FormGroup id={`group${id}`}>
            {/* <SearchLocationInput
              id={`location${id}`}
              callback={(props: any) => {
                const lat = props.geometry?.location?.lat() || 0;
                const lng = props.geometry?.location?.lng() || 0;
                setFieldValue("endLatitude", lat);
                setFieldValue("endLongitude", lng);
              }}
              autoCompleteRef={autoCompleteEndRef}
              setSelectedLocation={setSelectedLocation}
            /> */}
            <div className="search-location-input">
              <label className="form-label">{fieldName}</label>
              <input
                ref={autoCompleteRef}
                id={fieldName || "name"}
                name={fieldName || "name"}
                className="form-control"
                onChange={(event) => setQuery(event.target.value)}
                placeholder="Search Places ..."
                value={query}
                disabled={disabled}
              />
            </div>
          </FormGroup>
        </Col>
        {values.endLatitude &&
          <Col md={12}>
            <FormGroup>
              <Label>End Latitude - Longitude</Label>
              <div className="lat-long-badge">
                {values.endLatitude && (
                  <Badge>
                    {values.endLatitude}
                  </Badge>
                )}
                {values.endLongitude && (
                  <Badge>
                    {values.endLongitude}
                  </Badge>
                )}
              </div>
            </FormGroup>
          </Col>
        }
      </Row>
    </>
  );
}
