import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators";
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input } from "reactstrap";
import { arrowdown, uploadIcon } from "../../constant/icons";
import { Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { UPDATE } from "../../constant/commonConst";
import { PrintingApi } from ".";
import { getDate } from "../../utils/dateUtils";
import CustomDropDown from "../../components/common/dropdown";
import { UserAPI } from "../user";

export const DetailsButton = (props: any) => {
  const {
    dispatch,
    isDetailsRowShown,
    rowKeyValue,
  } = props;
  return (
    <Button
    className={isDetailsRowShown ? "hide-btn collapse-btn" : "show-btn collapse-btn"}
      onClick={() => {
        dispatch(
          isDetailsRowShown
            ? hideDetailsRow(rowKeyValue)
            : showDetailsRow(rowKeyValue)
        );
      }}
    >
      {/* {isDetailsRowShown ? "Hide" : "Show"} */}
      {arrowdown.icon}
    </Button>
  );
};
export const UploadButton = (props: any) => {
  const {

    column,
    rowData,

  } = props;
  return (
    <Button
      color="secondary"
      outline
      onClick={() => column.callBack(rowData)}
    >
      {uploadIcon.icon} Upload Creative
    </Button>
  );
};
export const DateField = (props: any) => {
  const { column, rowData } = props;
  const api = PrintingApi(true);

  return (
    <Formik
      initialValues={{ unmountingDate: props?.rowData?.unmountingDate }}
      onSubmit={(values) => {
        const requestBody = {
          id: props?.rowData?.id,
          unmountingDate: values.unmountingDate,
        };
        api.updateValue(requestBody).then((result) => {
          if(result.status){
            props.column.callBack({ data: result, status: UPDATE })
          }
        });
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Field
              component={DatePicker}
              placeholderText={"DD/MM/YYYY"}
              popperPlacement="bottom-end"
              disabled={props?.rowData?.status == "un-mounting"}
              popperModifiers={[
                {
                  name: "myModifier",
                  fn(state:any) {
                    // Do something with the state
                    return state;
                  },
                },
              ]}
              dateFormat={"dd/MM/yyyy"}
              className="form-control"
              name="unmountingDate"
              selected={getDate(values.unmountingDate)}
              onChange={(date: any) => {
                setFieldValue("unmountingDate", date);
                handleSubmit();
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export const StatusDropDown = (props: any) => {
  const { column, rowData } = props;
  const statusOptions = [
    {
      label: "Un-mounting is Pending",
      value: "un-mounting-pending",
      color: "primary",
    },
    { label: "Un-mounting", value: "un-mounting", color: "success" },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleSelect = (status: any) => {
    column.callBack({ data: status.value, rowData, status: UPDATE });
    setDropdownOpen(false);
  };
  return (
    <>
      <Dropdown
        className="status-dropdown"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle caret className="text-dark">
          <Badge
            color={
              statusOptions.find((option) => option.value === rowData.status)
                ?.color
            }
            className="text-dark"
          >
            {
              statusOptions.find((option) => option.value === rowData.status)
                ?.label
            }
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {statusOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => {
                handleSelect(option);
              }}
            >
              <Badge color={option.color} className="text-dark">
                {option.label}
              </Badge>
            </DropdownItem>
          ))}{" "}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export const Status = (props: any) => {
  const {rowData} =props
  const statusOptions = [
    {
      label: "Un-mounting is Pending",
      value: "un-mounting-pending",
      color: "primary",
    },
    { label: "Un-mounting", value: "un-mounting", color: "success" },
  ];
  
  return <Badge className="rounded-pill text-dark status-badge"
    color={
      statusOptions.find((option) => option.value === rowData.status)
        ?.color
    }
  >
    {rowData?.status}
  </Badge>;
};


export const AssignUserDropDown = (props: any) => {
  const userList = UserAPI(false);
  const api = PrintingApi(true);
  const convertOptions =
    userList?.data?.data &&
    userList?.data?.data?.length > 0 &&
    userList?.data?.data.map((user: any) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
  return (
    <Formik
      initialValues={{ assignTo: props?.rowData?.assignTo }}
      onSubmit={(values) => {
        const requestBody = {
          id: props?.rowData?.id,
          assignTo: values.assignTo,
        };
        api.updateValue(requestBody).then((result) => {
          if(result.status){
            props.column.callBack({ data: result, status: UPDATE })
          }
        });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <CustomDropDown
              fieldName="assignTo"
              options={convertOptions}
              setFieldValue={setFieldValue}
              callback={handleSubmit}
              values={values.assignTo}
              selectedValue={values.assignTo}
              gorupClass={"no-label"}
            />
          </Form>
        );
      }}
    </Formik>
  );
};