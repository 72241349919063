import { useEffect, useState } from "react";
import { DataType } from "ka-table";
import PageLayout from "../../components/layout/page";
import { DataView, TableColumn } from "../../components/table/table";
import { Button } from "reactstrap";
import { downloadIcon, filterIcon } from "../../constant/icons";
import { useApi } from "../../helper/apiHook";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from "moment";
import { PrintingFilter } from "./printingFilter";
import { getFormattedDate } from "../../utils/dateUtils";
import { BookingAPI } from "../booking";

export const printingColumns = (actions: any): TableColumn[] => {
    return [
        {
            key: "BOOKING NO",
            title: "BOOKING NO",
            dataType: DataType.String,
            // valueConvert: (value: string) => getFormattedDate(value)
        },
        {
            key: "TASK NO",
            title: "TASK NO",
            dataType: DataType.String,
        },
        {
            key: "QTY",
            title: "QTY",
            dataType: DataType.String,
        },
        {
            key: "SIZE",
            title: "SIZE",
            dataType: DataType.String,
        },
        {
            key: "MATERIAL",
            title: "MATERIAL",
            dataType: DataType.String,
        },
        {
            key: "RATE",
            title: "RATE",
            dataType: DataType.String,
        },
        {
            key: "DATE",
            title: "DATE",
            dataType: DataType.String,
        },
        {
            key: "PRINTING STATUS",
            title: "PRINTING STATUS",
            dataType: DataType.String,
        },
    ];
};

export default function Report() {
    const [filter, setFilter] = useState<boolean>(false);
    const [filterValues, setFilterValues] = useState<any>({});
    const [rows, setRows] = useState<any[]>([]);
    const [filteredRows, setFilteredRows] = useState<any[]>([]);

    const api = useApi("/printingtasks/printingReport", true, filterValues);
    const bookingApi = BookingAPI();

    // Fetch data initially or when filterValues change
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = api.data || [];
                console.log("data", data);

                const mappedData = Array.isArray(data) ? data.map((item: any) => ({
                    'BOOKING NO': item.bookingNo,
                    'TASK NO': item.taskNo,
                    'QTY': item.posterQty,
                    'SIZE': item.posterHeight * item.posterWidth,
                    'MATERIAL': item?.material?.name ? item?.material?.name : "",
                    'RATE': item.rate,
                    'DATE': item.deliveryDate ? moment(item.deliveryDate).format("DD/MM/YYYY") : 'N/A',
                    'PRINTING STATUS': item?.status ?? "-",
                })) : [];

                setRows(mappedData);
                setFilteredRows(mappedData); // Initially set filtered rows as all data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [api.data]);

    const handleShowFilter = () => {
        setFilter(!filter);
    };
    const today = moment().format('DD-MM-YYYY');

    const exportToExcel = async () => {
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Define the headers (removed 'ID' and added 'Client Name')
        const headers = [
            { header: 'BOOKING NO', key: 'bookingNo', width: 15 },
            { header: 'TASK NO', key: 'taskNo', width: 30 },
            { header: 'QTY', key: 'posterQty', width: 15 },
            { header: 'SIZE(Total SQ.FT)', key: 'size', width: 20 },
            { header: 'MATERIAL', key: 'material', width: 25 },
            { header: 'RATE', key: 'rate', width: 30 },
            { header: 'DATE', key: 'date', width: 30 },
            { header: 'PRINTING STATUS', key: 'status', width: 15 },
        ];

        // Add the header row with styles
        worksheet.columns = headers;

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '000' }, // black background
            };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });

        // Map your API data and remove the 'id', add 'clientName'
        const data = api?.data.map((item: any) => ({
            bookingNo: item.bookingNo,
            taskNo: item.taskNo,
            posterQty: item.posterQty,
            size: item.posterHeight * item.posterWidth,
            material: item?.material?.name,
            rate: item.rate,
            date: item.deliveryDate ? getFormattedDate(item?.deliveryDate) : '',
            status: item.status,
        }));

        // Add the data rows
        data.forEach((item: any) => {
            worksheet.addRow(item);
        });

        // Generate buffer for Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob and trigger the download
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `printReport${today}.xlsx`);
    };

    const bookingNoList = Array.isArray(bookingApi?.data)
        ? bookingApi?.data
            .map((name: any) => ({ value: name?.bookingNo, label: name?.bookingNo }))
        : [];

    return (
        <>
            <div className="d-flex justify-content-end align-items-end" style={{ paddingRight: '1rem' }}>
                <div className="head-btn-box">
                    <Button color="secondary" type="button" outline onClick={exportToExcel}>
                        {downloadIcon.icon}
                        Download
                    </Button>
                    <Button
                        color="secondary"
                        type="button"
                        outline
                        onClick={handleShowFilter}
                    >
                        {filterIcon.icon}
                        Filter
                    </Button>
                </div>
            </div>
            <PageLayout
                View={
                    <DataView
                        columns={printingColumns({ api: {} })}
                        rows={filteredRows}
                        currentPage={api.currentPage}
                        rowCount={api.rowCount}
                        limit={api.limit}
                    />
                }
            />
            {filter && (
                <PrintingFilter
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    onCloseFilter={handleShowFilter}
                    bookingNoList={bookingNoList}
                />
            )}
        </>
    );
}
