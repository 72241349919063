import { Form, Formik } from "formik";
import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators";
import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
// import Dropdown from "../../components/common/dropdown";
import { arrowdown, fileupload, uploadIcon } from "../../constant/icons";
import { UPDATE } from "../../constant/commonConst";
import ProtectedImage from "../../components/common/image";
import { DetailControl } from "../../components/buttons/detail";

export const DetailsButton = (props: any) => {
  const { dispatch, isDetailsRowShown, rowKeyValue } = props;
  return (
    <Button
      className={
        isDetailsRowShown ? "hide-btn collapse-btn" : "show-btn collapse-btn"
      }
      onClick={() => {
        dispatch(
          isDetailsRowShown
            ? hideDetailsRow(rowKeyValue)
            : showDetailsRow(rowKeyValue)
        );
      }}
    >
      {/* {isDetailsRowShown ? "Hide" : "Show"} */}
      {arrowdown.icon}
    </Button>
  );
};
export const UploadButton = (props: any) => {
  const { column, rowData } = props;
  const parseCreativeUrl =JSON.parse(rowData?.creativeUrl);
  const findUploadimageUrl =parseCreativeUrl && parseCreativeUrl.length > 0 ? parseCreativeUrl.find((finduploadimg:any)=>finduploadimg.tagname == "image"):null;
  return (
    <>{
      findUploadimageUrl?.url ?  <div className="table-img-box"><ProtectedImage callBack={() => column.callBack(rowData)} value={findUploadimageUrl?.url}/> </div> :  <Button color="secondary" outline onClick={() => column.callBack(rowData)}>
        {uploadIcon.icon} Upload Creative
      </Button>
    }</>
  );
};

export const StatusDropDown = (props: any) => {
  const { column, rowData } = props;
  const statusOptions = [
    {
      label: "Waiting for creative",
      value: "waiting-for-creative",
      color: "warning",
    },
    {
      label: "Creative received",
      value: "creative-received",
      color: "primary",
    },
    { label: "Printing done", value: "printing-done", color: "success" },
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleSelect = (status: any) => {
    column.callBack({ data: status.value, rowData, status: UPDATE })
    setDropdownOpen(false);
  };
  return (
    <>
      <Dropdown
        className="status-dropdown"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle caret className="text-dark">
          <Badge
            color={
              statusOptions.find((option) => option.value === rowData.status)
                ?.color
            }
            className="text-dark"
          >
            {statusOptions.find((option) => option.value === rowData.status)
              ?.label}
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {statusOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => {
                handleSelect(option);
              }}
            >
              <Badge color={option.color} className="text-dark">
                {option.label}
              </Badge>
            </DropdownItem>
          ))}{" "}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export const isValidImageUrl = (url: any) => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const ImageShow = (props: any) => {
  const { rowData } = props;
  const [firstImageUrl, setFirstImageUrl] = useState("");
  useEffect(() => {
    if (rowData.creativeUrl) {
      try {
        const urls = JSON.parse(rowData.creativeUrl);
        if (urls.length > 0) {
          const url = urls[0];
          if (isValidImageUrl(url)) {
            setFirstImageUrl(url);
          } else {
            console.log("object");
          }
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [rowData.creativeUrl]);
  return (
    <>
      <div>
        {firstImageUrl ? (
          <img
            src={firstImageUrl}
            alt="preview"
            style={{
              maxWidth: "50%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              margin: "auto",
              height: "50%",
            }}
          />
        ) : (
          <p>No Image Uploaded</p>
        )}
      </div>
    </>
  );
};

export const PrintingActionButton = (props: any) => {
  return (
    <div className="action_btn table-action">
      <DetailControl {...props} />
    </div>
  );
};

export const Status = (props: any) => {
  const {rowData} =props
  const statusOptions = [
    {
      label: "Waiting for creative",
      value: "waiting-for-creative",
      color: "warning",
    },
    {
      label: "Creative received",
      value: "creative-received",
      color: "primary",
    },
    { label: "Printing done", value: "printing-done", color: "success" },
  ];
  
  return <Badge className="rounded-pill text-dark status-badge"
    // color={rowData?.status ? "success":"warning"}
    color={
      statusOptions.find((option) => option.value === rowData.status)
        ?.color
    }
  >
    {/* {rowData?.status ? "Done":"Pending"} */}
    {rowData?.status}
  </Badge>;
};