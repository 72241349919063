import { Button } from "reactstrap";
import PageLayout from "../../../components/layout/page";
import { useAddUpdate } from "../../../helper/addUpdateHook";
import { useApi } from "../../../helper/apiHook";
import { AddUpdateItem } from "./addUpdate";
import { downloadIcon, imageIcon, plus, uploadIcon } from "../../../constant/icons";
import { columns } from "./columns";
import { DataView } from "../../../components/table/table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiHelper from "./helper";
import ModalView from "../../../components/modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { invokeApi } from "../../../helper/api";
import { GET, POST } from "../../../constant/commonConst";
import { message } from "../../../components/toast";

interface FormValues {
  file: File | null; // Explicitly define the type for the file
}

export const MediaItemsApi = (
  paging?: boolean,
  dataLoad: any = {},
  body: any = {}
) => {
  return { ...useApi("/mediaItems", paging, body, dataLoad) };
};

const validationSchema = Yup.object().shape({
  file: Yup.mixed().required("An Excel file is required"),
});

export default function MediaItems() {
  const api = MediaItemsApi(true);
  const AddUpdate = useAddUpdate();
  const { stateList } = apiHelper();
  useEffect(() => {
    api.mutate();
  }, []);

  const [isModal, setIsModal] = useState(false);
  const handleClose = () => setIsModal(false);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const exportToExcel = async () => {
    try {
      const response = await invokeApi(
        GET,
        '/mediaItems/downloadZip',
        undefined,
        undefined,
        { responseType: "blob" }
      );

      if (response instanceof Blob) {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MediaItemsTemplate.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('No valid Blob response:', response);
      }
    } catch (error) {
      console.error("Error downloading the zip file:", error);
    }
  };


  return AddUpdate.viewAddUpdate ? (
    <AddUpdateItem
      closeAddUpdate={AddUpdate.closeAddEditFrom}
      api={api}
      value={AddUpdate.currentValue}
    />
  ) : (
    <>
      <PageLayout
        item={
          <>
            {" "}
            <Button color="secondary" onClick={() => setIsModal(true)}>
              {plus.icon}Upload Excel
            </Button>
            <Button color="secondary" onClick={exportToExcel}>
              {downloadIcon.icon}Download Template
            </Button>
            <Button
              color="secondary"
              onClick={() =>
                AddUpdate.openAddEditFrom({
                  name: "",
                  stateId:
                    stateList.find((x: any) => x.label === "Gujarat")?.value ||
                    null,
                  cityId: null,
                  zoneId: null,
                  locationId: null,
                  latitude: null,
                  agencyId: null,
                  longitude: null,
                  landlordId: null,
                  mediaTypeId: null,
                  mediaLitId: null,
                  mediaSeriesId: null,
                  width: null,
                  height: null,
                  qty: 1,
                  BalanceQty: null,
                  IsActivate: true,
                  landlordInfo: [],
                  image: [],
                })
              }
            >
              {plus.icon}Add New
            </Button>
          </>
        }
        View={
          <DataView
            columns={columns({
              api,
              openAddEditFrom: AddUpdate.openAddEditFrom,
              view: navigate,
            })}
            rows={api.data}
            updatePagination={api.updatePagination}
            currentPage={api.currentPage}
            rowCount={api.rowCount}
            limit={api.limit}
          />
        }
      />
      <ModalView
        modalView={isModal}
        modalName={"Upload Excel"}
        onModalDismiss={handleClose}
      >
        <Formik<FormValues>
          initialValues={{ file: null }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            if (!values.file) return;

            const formData = new FormData();
            formData.append("file", values.file);

            try {
              const res = await invokeApi(POST, "/mediaItems/import", formData);
              const { data } = res?.data || {};

              if (data?.length > 0) {
                const errors = data.errors;
                if (!errors) {
                  message("success", "File uploaded successfully!");
                } else {
                  message("success", `File uploaded with ${errors}`);
                }
                resetForm();
                handleClose();
                window.location.reload();
              } else {
                message("error", res.data.errors || "An unknown error occurred.");
              }
            } catch (error) {
              message("error", "Failed to upload file. Please try again.");
              console.error("Error uploading file:", error);
            }
          }}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form>
              <div className="dropzone mb-2">
                <label className="dropzone-container" htmlFor="file">
                  <div className="file-icon">
                    {values.file ? (
                      <span>{(values.file.name)}</span>
                    ) : (
                      uploadIcon.icon
                    )}
                  </div>
                  <div className="dropzone-title">
                    {!values.file && (
                      <>
                        Drag and drop your Excel File or
                        <span className="browse"> browse</span> your Excel File
                      </>
                    )}
                  </div>
                </label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  onChange={(event) => {
                    if (event.currentTarget.files) {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }
                  }}
                  accept=".xlsx, .xls"
                />
                {errors.file && touched.file ? (
                  <div style={{ color: "red" }}>{errors.file}</div>
                ) : null}
              </div>
              <div className="d-flex justify-content-end">
                <Button color="secondary" solid type="submit">
                  Submit
                </Button>
              </div>


            </Form>
          )}
        </Formik>
      </ModalView>
    </>
  );
}
