import React, { useState } from "react";
import { EditControl } from "../../components/buttons/edit";
import { RemoveControl } from "../../components/buttons/remove";

import { DuplicateControl } from "../../components/buttons/dublicate";
import { SendControl } from "../../components/buttons/send";
import { GenrateControl } from "../../components/buttons/genrate";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import { downloadIcon } from "../../constant/icons";
import GeneratePdf from "./pdfGenerate/generatePdf";

export const ActionButton = (props: any) => {
  return (
    <div className=" table-action">
      {/* <span> {
        props?.rowData?.proposalDocUrl ? <DownloadPPTButton {...props} /> : <GenrateControl {...props} />
      }</span> */}

      <span><SendControl {...props} /></span>
      <span><DuplicateControl {...props} /></span>
      <span> <GeneratePdf {...props} /></span>
      <span><EditControl {...props} /></span>
      <span>{props?.rowData?.isProposalBooked?.id == props?.rowData?.id ? (
        <BookingButton {...props} />
      ) : (
        <RemoveControl {...props} />
      )}</span>





    </div>
  );
};

export const BookingButton = (props: any) => {
  const { column, rowData } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <>
      <Button
        color="link"
        onClick={() => {
          if (column.callBack) {
            column.callBack({ data: rowData, status: "booked" });
          }
        }}
        className="text-danger"
        id="booktooltip"
      >
        Booked
      </Button>
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        target="booktooltip"
        toggle={toggleTooltip}
      >
        Booked
      </UncontrolledTooltip>
    </>
  );
};

const DownloadPPTButton = (props: any) => {
  const { column, rowData } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <>
      <Button
        color="link"
        onClick={() => {
          if (column.callBack) {
            column.callBack({ data: rowData, status: "download" });
          }
        }}
        className="text-danger"
        id="donwloadtooltip"
      >
        {downloadIcon.icon} Download

      </Button>
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        target="donwloadtooltip"
        toggle={toggleTooltip}
      >
        Download
      </UncontrolledTooltip>
    </>
  );
};