import { Formik } from 'formik'
import { FormGroup, Input, Label } from 'reactstrap'
import { useEffect, useState } from 'react';
import { useApi } from '../../../../helper/apiHook';
import { PrintingApi } from '../..';

const GetAllMounting = (paging?: boolean, dataLoad: any = {}) => {
    return { ...useApi("/unmountings/getAll", paging, undefined, dataLoad) };
};
const SelectStatus: React.FC<any> = (props) => {
    const [singleData, setSingleData] = useState<any>(null);
    const mounting = PrintingApi(true);
    const apiMountingList = GetAllMounting(true);
    useEffect(() => {
        setSingleData(props?.value)
    }, [props?.value])
    return (
        <Formik
            initialValues={{
                status: singleData?.status ?? "",
                id: singleData?.id ?? ""
            }}
            enableReinitialize={true}
            onSubmit={async (value) => {
                mounting.updateValue(value).then((result: any) => {
                    if (result.status) {
                        if (props?.api) {
                            apiMountingList.mutate();
                            if (props.callBack) {
                                props.callBack(value?.status);
                            }
                        }
                    }
                });
            }}
        >
            {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
                <FormGroup noMargin onClick={(e: any) => {
                    if (e && e.stopPropagation) {
                        e.stopPropagation();
                    }
                }}>
                    {props.label ? <Label>Status</Label>
                        : null}
                    <Input
                        id="exampleSelect"
                        name="status"
                        type="select"
                        value={values?.status}
                        onChange={(e: any) => {
                            setFieldValue("status", e.target.value)
                            handleSubmit(e)
                        }}
                    >
                        <option value={"waiting-for-creative"}>
                            To do
                        </option>
                        <option value={"un-mounting-pending"}>
                            In progress
                        </option>
                        <option value={"unmounted"}>
                            Completed
                        </option>
                    </Input>
                </FormGroup>
            )}

        </Formik>
    )
}

export default SelectStatus