import { Badge, Button, FormGroup, Input, Label } from "reactstrap";
import { capitalizeFirstLetter } from "../../utils/generalUtils";

const StatusChangeControl = (props: any) => {
    const { disabled, column, rowData, value, message } = props;

    return (<FormGroup check switch className="status-switch">
        {value === "open" ? <Input type="switch" role="switch" checked={value === "open"} onChange={async (e) => {
            if (column.callBack) {
                column.callBack({ ...rowData, checkBoxChecked: e.target.checked })
            }
        }} /> :
        <Input type="switch" role="switch" checked={value === "active"} onChange={async (e) => {
            if (column.callBack) {
                column.callBack({ ...rowData, checkBoxChecked: e.target.checked })
            }
        }} />
        }
        {/* <Label check>{capitalizeFirstLetter(value)}</Label> */}
    </FormGroup>)
};

export { StatusChangeControl };