import AddCellTable from "../../../components/table/addCellTable";
import ModalView from "../../../components/modal";
import { RemoveControl } from "../../../components/buttons/remove";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { DateEdit } from "../actions";
import { uuid } from "../../../utils/generalUtils";

export default function TableExtent({ mediaInventory, bookingStartDate, setFieldValue, isModal, setIsModal, dbId, columns }: any) {
  const [localData, setLocalData] = useState<any>(isModal?.data ? isModal?.data?.subMediaInventory :mediaInventory);
  useEffect(()=>{
    const updateDates = (item: any) => {
      const endDate = new Date(item.endDate);
      const startDate = endDate ? new Date(endDate.getTime() + 24 * 60 * 60 * 1000) : item.startDate; // +1 day
      const newEndDate = endDate ? new Date(endDate.getTime() + 31 * 24 * 60 * 60 * 1000) : item.endDate; // +31 days
  
      return {
        ...item,
        startDate: startDate.toISOString(),
        endDate: newEndDate.toISOString(),
      };
    };
  
    if (isModal?.data) {
      const subMediaInventory = isModal.data.subMediaInventory.map(updateDates);
      setLocalData(subMediaInventory);
    } else {
      const updatedMediaInventory = mediaInventory.map(updateDates);
      setLocalData(updatedMediaInventory);
    }
  },[mediaInventory,isModal?.data]);
  
  const deleteValue = (recod: any) => {
    const tempRowIdToRemove = recod?.data?.tempRowId;
    // Ensure tempRowId is available before proceeding
    if (tempRowIdToRemove) {
      // Create a new state by filtering out the item to delete
      setLocalData((prevTempData: any[]) => {
        // Filter the items to exclude the one with the matching tempRowId
        const updatedTempData = prevTempData.filter(
          (element: any) => element.tempRowId !== tempRowIdToRemove
        );

        // Return a new array with the filtered data to update the state
        return [...updatedTempData];
      });
    }
  }
  const changeValue =(value:any,rowData:any,key:string)=>{   
    setLocalData((prevTempData: any[])=>{
      const findValueChange = prevTempData && prevTempData.length > 0 && prevTempData.map((element:any)=>{
        if(element?.tempRowId == rowData?.tempRowId){
          return {
            ...element,
            [key]: value
          }
        }else{
          return element
        }
      })
    return findValueChange;
    })
  }
  const newColumns = columns && columns.length > 0 && columns.map((element:any)=>{
    if(element?.key == "addColumn"){
      return {
          ...element,
          key:"actions",
          title:"Actions",
          component: RemoveControl,
          style: { width: "3vw", textAlign: 'center' },
          callBack:deleteValue
      }
  }
    if(element?.showDatepicker){
      return{
        ...element,
        component:DateEdit,
        style: { width: "14vw" },
        callback:changeValue
      }
    }
  if(element?.isEditable){
    return{
      ...element,
      isExtentEditable:true,
      callback:changeValue
    }
  }
  if(!element?.isDisabled){
    return{
      ...element,
      isDisabled:true,
      callback:changeValue
    }
  }
  return element
  })
  const saveExtentsValue=()=>{
      const addDisableKey = mediaInventory && mediaInventory.length >0 ?  mediaInventory.map((element:any)=>{
        const findTempidRow = localData && localData.length > 0 ? localData.find((elementemprow:any)=>elementemprow.tempRowId == element.tempRowId):null;
        if(element?.tempRowId == findTempidRow?.tempRowId){
          return {
            ...element,
            startDate:findTempidRow?.startDate,
            endDate:findTempidRow?.endDate,
            amount:findTempidRow?.amount,
            subMediaInventory:findTempidRow ? [{...findTempidRow,tempRowId:uuid()},element]:[element],
            isHaveSubMediaInventory:true
          }
        }
        return element;
      }):[];
    setFieldValue("mediaInventory",addDisableKey)
    setIsModal({
      ...isModal,
      open:false,
    })
  }
  const updateExtentvalue=()=>{
    const updateMediaInventory = mediaInventory && mediaInventory.length > 0 ?  mediaInventory.map((element:any)=>{
      const findTempidRow = mediaInventory && mediaInventory.length > 0 ? mediaInventory.find((elementemprow:any)=>elementemprow.tempRowId == isModal?.data?.tempRowId):null;
      if(element?.tempRowId == findTempidRow?.tempRowId){
        const result = localData && localData.length > 0 ?  localData.reduce((acc:any, num:any) => {
          const startDate = new Date(num.startDate);
          const endDate = new Date(num.endDate);
          if (!acc.minStartDate || startDate < acc.minStartDate) {
            acc.minStartDate = startDate;
          }
          if (!acc.maxEndDate || endDate > acc.maxEndDate) {
            acc.maxEndDate = endDate;
          }
          const diffTime = acc.maxEndDate - acc.minStartDate;
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          acc.qty += num?.qty;
          acc.amount += num?.amount;
          acc.rate = acc.amount/acc.qty;
          return {...acc,diffDays};
        }, {
          qty:0,
          day:0,
          rate:0,
          minStartDate: null,
          maxEndDate: null,
          amount:0
        }):null;
          return  {
            ...findTempidRow,
            qty:result?.qty,
            rate:result?.rate,
            day:result?.diffDays,
            startDate: result?.minStartDate ? new Date(result.minStartDate).toISOString() : null,
            endDate: result?.maxEndDate ? new Date(result.maxEndDate).toISOString() : null,
            amount:result?.amount,
            subMediaInventory:localData,
            isHaveSubMediaInventory:true,
          };
    
      }
      return element
      }):[];
    setFieldValue("mediaInventory",updateMediaInventory)
    setIsModal({
      ...isModal,
      open:false,
    })
  }
return (
    <>
      <ModalView
        modalView={isModal?.open}
        customClass="booking-extends"
        modalsize="xl"
        modalName={dbId}
        onModalDismiss={() => setIsModal({
          ...isModal,
          open:false,
        })}
      >
        <AddCellTable
          bookingStartDate={bookingStartDate}
          groupCode={dbId}
          updateFieldValue={() => { }}
          columns={newColumns}
          setIsModal={setIsModal}
          rows={localData}
        />
        <Button onClick={()=>{
          if(isModal?.data?.isHaveSubMediaInventory){
            updateExtentvalue();
          }else{
            saveExtentsValue()
          }
        }}>{isModal?.data?.isHaveSubMediaInventory ? "Update Extents" :"Save Extents"}</Button>
      </ModalView>
    </>
  );
}
