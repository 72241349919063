
import { useLoader } from '../../../components/loader';
import { GUJARAT_GST_CODE } from '../../../constant/commonConst';
import { fetcher } from '../../../helper/api';
import logoYellow from '../../../assets/images/logo_yellow.png';
import { generateRows } from './headerColumns';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useState } from 'react';
import { DownloadPdf } from '../actions';

export default function GeneratePdf(props: any) {
  const { rowData } = props
  const loading = useLoader();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const doc: any = new jsPDF();
  const normalizeColumnName = (col: any) => col.replace(/\s+/g, "").toLowerCase();
  const headerHeight = 10;
  const headerY = 130;

  let y = 0; // Initialize y position
  let isLastPage = false; // Variable to track if we are on the last page

  const addHeader = (bookingData:any) => {
    // Add header image
    doc.addImage(logoYellow, "PNG", 17, 15, 40, 50); // Adjust x, y, width, height as needed
    // Add header text
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("ADVERTISING SERVICES", 130, 25); // Adjust x, y as needed

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("BOOKING SHEET", 155, 30); // Adjust x, y as needed

    doc.setFontSize(14);
    doc.setTextColor(255, 204, 0); // Dark yellow color
    doc.text("BOOKING SHEET", 120, 50); // Adjust x, y as needed
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Booking ID: ${bookingData.prososalNo || ''}`, 120, 55); // Dynamic Booking ID
    doc.text(`Booking Date: ${new Date(bookingData.createDate).toLocaleDateString()}`, 120, 60); // Dynamic Booking Date
    doc.text(`Booking By: ${bookingData.contactPerson || ''}`, 120, 65); // Dynamic Contact Person


    // Add Booking from
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(255, 204, 0); // Dark yellow color
    doc.text("Booking From", 17, 75); // Adjust x, y as needed

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0); // Reset to black or default color for content
    doc.text("CRAFT MEDIA", 17, 80); // Adjust x, y as needed

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text("A-409, The Landmark, URJANAGAR-I", 17, 85); // Adjust x, y as needed
    doc.text("Kudasan, 382421, Gandhinagar - GUJ (IND)", 17, 90); // Adjust x, y as needed
    doc.text("GSTIN / UNI: 24BVWPM5351E1ZB", 17, 95); // Adjust x, y as needed
    doc.text("STATE NAME: GUJARAT", 17, 100); // Adjust x, y as needed
    doc.text("PAN: 24BVWPM5351E", 17, 105); // Adjust x, y as needed
    doc.text(`CONTACT NO: +91 9586622346, +91 9586522346 `, 17, 110); // Adjust x, y as needed

    // Add Booking for
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(255, 204, 0); // Dark yellow color
    doc.text("Booking For", 100, 75); // Adjust x, y as needed

    // Add Booking details
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0); // Reset to black or default color for content
    doc.text(`${bookingData.client?.contactPerson || ''}`, 100, 80); // Adjust x, y as needed

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(`ADDRESS ${bookingData.client?.billingAddress}`, 100, 85); // Adjust x, y as needed
    doc.text(`GSTIN / INI: ${bookingData.client?.GSTIN}`, 100, 90); // Adjust x, y as needed
    doc.text("PLACE OF SUPPLY:", 100, 95); // Adjust x, y as needed
    doc.text("STATE NAME:", 100, 100); // Adjust x, y as needed
    doc.text("STATE CODE:", 100, 105); // Adjust x, y as needed
    doc.text(`PAN: ${bookingData.client?.PANNo}`, 100, 110); // Adjust x, y as needed
    doc.text(`CONTACT NO: ${bookingData.client?.contactPersonMobileNo}`, 100, 115); // Adjust x, y as needed
  };

  // Add "SUMMARY OF SERVICES CHARGES" header with a dark yellow background and border
  const addTableHeader = () => {
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0); // Black text color
    doc.text("SUMMARY OF SERVICES CHARGES", 15, headerY - headerHeight / 2); // Adjust x, y to center the text

    // Function to add section titles and tables
    y = headerY + 10; // Initial y position for tables after the header
  };

  const addFooter = (bookingData: any) => {
    const SubTotalAmount = bookingData.mediaInventory.reduce((acc: any, item: any) => acc + (item.amount || 0), 0);
    const igstAmount = SubTotalAmount * 0.18;
    const cgstAmount = SubTotalAmount * 0.09;
    const sgstAmount = SubTotalAmount * 0.09;
    const isGujaratGstNo = bookingData?.client?.GSTIN ? bookingData?.client?.GSTIN?.substring(-2) == GUJARAT_GST_CODE ? true : false : false

    // Set default font properties
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 0, 0); // Black text color

    // Add footer left
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("TERMS & CONDITIONS", 17, doc.internal.pageSize.height - 65); // Adjust x, y as needed

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("gray"); // Dark yellow color
    doc.text("Total In Words", 17, doc.internal.pageSize.height - 55); // Adjust x, y as needed

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0); // Reset to black or default color for content
    doc.text(
      "Call - 95866 22346, 9586522346",
      17,
      doc.internal.pageSize.height - 50
    ); // Adjust x, y as needed
    doc.text(
      "E mail - info.craftmedia@gmail.com",
      17,
      doc.internal.pageSize.height - 45
    ); // Adjust x, y as needed
    doc.text(
      "Web - wwww.craftmedia@gmai.com",
      17,
      doc.internal.pageSize.height - 40
    ); // Adjust x, y as needed

    // Add footer right
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(`SUB TOTAL IN INR: ${SubTotalAmount?.toFixed(2)}`, 130, doc.internal.pageSize.height - (isGujaratGstNo ? 70 : 65)); // Adjust x, y as needed
    { isGujaratGstNo && doc.text(`IGST @ 18%: ${igstAmount?.toFixed(2)}`, 130, doc.internal.pageSize.height - 65); }
    doc.text(`CGST @ 9%: ${cgstAmount?.toFixed(2)}`, 130, doc.internal.pageSize.height - 60);// Adjust x, y as needed
    doc.text(`SGST @ 9%: ${sgstAmount?.toFixed(2)}`, 130, doc.internal.pageSize.height - 55); // Adjust x, y as needed

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("gray"); // Dark yellow color
    const totalTextX = 130;
    const totalTextY = doc.internal.pageSize.height - 47; // Y position of "Total (INR)"

    doc.text(`Total (INR) ${isGujaratGstNo ? (SubTotalAmount + igstAmount)?.toFixed(2) : (SubTotalAmount + cgstAmount + sgstAmount)?.toFixed(2)}`, totalTextX, totalTextY); // Add the "Total (INR)" text

    // Draw top and bottom borders around the "Total (INR)" text
    doc.setDrawColor(0, 0, 0); // Black color for the border
    doc.setLineWidth(0.5); // Set border width to 0.5

    // Draw the top border
    doc.line(totalTextX, totalTextY - 6, totalTextX + 65, totalTextY - 6);

    // Draw the bottom border
    doc.line(totalTextX, totalTextY + 2, totalTextX + 65, totalTextY + 2);

    // Add footer addresses
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0); // Reset to black or default color for content
    doc.text(
      "Reg Office. 86, Shakti Complex, Opp Krishna Hospital,",
      120,
      doc.internal.pageSize.height - 40
    ); // Adjust x, y as needed
    doc.text(
      "Mota Chiloda, NH-8, 382 355 Gandhinagar - Guj (IND)",
      120,
      doc.internal.pageSize.height - 35
    ); // Adjust x, y as needed
    doc.text(
      "Corp Office, A-409, The Landmark, Urjanagar 1",
      120,
      doc.internal.pageSize.height - 30
    ); // Adjust x, y as needed
    doc.text(
      "Kudasan, 382 421 Gandhinagar - Guj (IND)",
      120,
      doc.internal.pageSize.height - 25
    ); // Adjust x, y as needed
  };

  const printPDF = async () => {
    const bookingData: any = await fetcher('/booking', { id: rowData.id }, loading)

    // Header of page
    addHeader(bookingData);

    // Border for header and table
    doc.setFillColor(255, 204, 0); // Dark yellow color
    doc.rect(13, headerY - headerHeight, 184, headerHeight, "F"); // Fill the rectangle with background color
    doc.setDrawColor(0, 0, 0); // Black border color
    doc.setLineWidth(0.5);
    doc.rect(13, headerY - headerHeight, 184, 155, "S"); // Draw the border

    // "Summary of services charges" table header
    addTableHeader();

    const addSectionTitle = (title: string) => {
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text(title, 15, y + 2);
      y += 5; // Add space after title
    };

    const renderTable = (section: any) => {
      const columns = section.columns.map((col: any) => ({
        title: col,
        dataKey: normalizeColumnName(col),
      }));

      const rows = section.rows.map((row: any) => {
        let rowData: any = {};
        section.columns.forEach((col: any) => {
          const normalizedCol = normalizeColumnName(col);
          rowData[normalizedCol] = row[normalizedCol] || "N/A";
        });
        return rowData;
      });

      addSectionTitle(section.title);

      doc.autoTable({
        startY: y,
        body: rows,
        columns: columns,
        theme: "striped",
        margin: { top: 30, bottom: 27, left: 15 },
        headStyles: {
          fillColor: [255, 255, 255], // White background for header
          textColor: [128, 128, 128], // Gray text color for header
          fontSize: 8, // Font size for headers
        },
        bodyStyles: {
          textColor: [0, 0, 0], // Black text color for body
          fontSize: 8, // Font size for headers
        },
        styles: { cellPadding: 1 },
        didDrawPage: (data: any) => {
          const margin = 10;
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;

          // Draw the 1px border on each page
          doc.setDrawColor(0, 0, 0); // Black border color
          doc.setLineWidth(0.5);
          doc.rect(margin, 15, pageWidth - 2 * margin, pageHeight - 2 * 15); // Draw the border

          if (data.pageNumber > 1) {
            // Add section title on each page
            doc.setFontSize(10);
            doc.setFont("helvetica", "bold");
            doc.setTextColor(0, 0, 0); // Black text color
            doc.text(section.title, 17, margin + 15); // Adjust x, y for section title
          }

          if ( doc.internal.getNumberOfPages() > 1) {
            doc.setDrawColor(0, 0, 0); // Black border color
            doc.setLineWidth(0.5);
            doc.rect(13, 17, pageWidth - 26, pageHeight - 3 * 13); // Adjust the border dimensions
          }
          y = doc.lastAutoTable.finalY + 10; // Update y position after table

          if (data.pageNumber === doc.internal.getNumberOfPages()) {
            isLastPage = true; // Mark the current page as the last page
          }
        },
        didDrawCell: (data: any) => {
          if (data.column.raw) {
            // Draw top and bottom borders for cells
            const { x, y, width, height } = data.cell;
            doc.setDrawColor(0, 0, 0); // Black color for borders
            doc.setLineWidth(0.5); // 0.5px line width

            // Draw top border
            doc.line(x, y, x + width, y);

            // Draw bottom border
            doc.line(x, y + height, x + width, y + height);
          }
        },
      });

      // Update y position after table
      y = doc.lastAutoTable.finalY + 10; // Add space after table

      // Check if there's enough space for footer; if not, add a new page
      const remainingSpace = doc.internal.pageSize.height - y - 30; // 30 is estimated footer height
      if (remainingSpace < 30) {
        // If not enough space
        doc.addPage();
        y = 20; // Reset y position for new page
        // Ensure the border is on the new page as well
        doc.setDrawColor(0, 0, 0); // Black border color
        doc.setLineWidth(0.5);
        doc.rect(
          10,
          15,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 30
        ); // Draw the border
      }
    };
    const headerColumns = [
      {
        title: 'SALE OF SPACE FOR OUTDOOR ADVERTISEMENT HOARDING',
        columns: ['MEDIA ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER MONTH', 'ST DATE', 'END DATE', 'AMOUNT'],
        rows: bookingData.mediaInventory.filter((item: any) => item.groupCode === 'SOAH').length > 0
          ? generateRows(bookingData.mediaInventory.filter((item: any) => item.groupCode === 'SOAH'), 'mediaItemId')
          : null,
      },
      {
        title: 'PURCHASE OF SPACE FOR OUTDOOR ADVERTISEMENT HOARDING',
        columns: ['AGENCY ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER MONTH', 'ST DATE', 'END DATE', 'AMOUNT'],
        rows: bookingData.mediaInventory.filter((item: any) => item.groupCode === 'POAH').length > 0
          ? generateRows(bookingData.mediaInventory.filter((item: any) => item.groupCode === 'POAH'), 'agencyId')
          : null,
      },
      {
        title: 'FLEX BANNER PRINTING HOARDING',
        columns: ['MEDIA ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER SQ FT', 'MATERIAL', 'AMOUNT'],
        rows: bookingData.mediaInventory.filter((item: any) => item.groupCode === 'FBPH').length > 0
          ? generateRows(bookingData.mediaInventory.filter((item: any) => item.groupCode === 'FBPH'), 'agencyId')
          : null,
      },
      {
        title: 'FLEX BANNER MOUNTING',
        columns: ['MEDIA ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER SQ FT', 'AMOUNT'],
        rows: bookingData.mediaInventory.filter((item: any) => item.groupCode === 'FBM').length > 0
          ? generateRows(bookingData.mediaInventory.filter((item: any) => item.groupCode === 'FBM'), 'agencyId')
          : null,
      },
      {
        title: 'SALE OF SPACE FOR OUTDOOR ADVERTISEMENT POLE KIOSK',
        columns: ['ROUTE ID', 'CITY', 'LOCATION', 'SIZE', 'SQ FT', 'QTY', 'RATE PER MONTH', 'ST DATE', 'END DATE', 'AMOUNT'],
        rows: bookingData.mediaInventory.filter((item: any) => item.groupCode === 'SOAPK').length > 0
          ? generateRows(bookingData.mediaInventory.filter((item: any) => item.groupCode === 'SOAPK'), 'routeId')
          : null,
      },
      {
        title: 'FLEX BANNER PRINTING AND MOUNTING POLE KIOSK',
        columns: ['MEDIA ID', 'ZONE', 'MEDIA TYPE', 'SIZE', 'SERIES', 'QTY', 'RATE PER POLE KIOSK', 'MATERIAL', 'AMOUNT'],
        rows: bookingData.mediaInventory.filter((item: any) => item.groupCode === 'FPMPK').length > 0
          ? generateRows(bookingData.mediaInventory.filter((item: any) => item.groupCode === 'FPMPK'), 'mediaItemId')
          : null,
      }
    ].filter(section => section.rows !== null);
    // Iterate over each section and render tables
    headerColumns.forEach((section) => {
      renderTable(section);
    });

    // Add footer
    if (isLastPage) {
      addFooter(bookingData);
    }

    // Save the PDF
    doc.save(`${rowData.bookingNo}.pdf`);
  };
  return (
    <>
      <DownloadPdf printPDF={printPDF} tooltipOpen={tooltipOpen} toggleTooltip={toggleTooltip} />
    </>
  );
}
